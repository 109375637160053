import React, { useState, useEffect, useContext } from "react";
import { Typography } from "@mui/material";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import { LineChart } from "@mui/x-charts";
import { UserContext } from "../../contexts/UserContext";
import { shortMonth } from "../../utilities/utilities";

const Tableau10 = [
    '#4e79a7',
    '#f28e2c',
    '#e15759',
    '#76b7b2',
    '#59a14f',
    '#edc949',
    '#af7aa1',
    '#ff9da7',
    '#9c755f',
    '#bab0ab',
  ];

export default function SingleVehiclePriceGraphViewModal(props){
    const {selectedVehicle, graphModalOpen, setGraphModalOpen} = props;

    const [priceSeries, setPriceSeries] = useState([0,0]);
    const [xAxis, setXAxis] = useState([0,0]);
    const user = useContext(UserContext);

    const GET_VEHICLE_PRICE_HISTORY = `${user.apiUrl}getVehiclesPriceHistory`;

    useEffect(() => {
        if(!selectedVehicle)return;
        fetch(`${GET_VEHICLE_PRICE_HISTORY}?vehicles=[${selectedVehicle.ymmIds}]`, {headers:{Authorization: `Bearer ${user.token}`}})
            .then((resp) => resp.json())
            .then((data) => {
                const arr = [];
                const xArr = [];
                data.data.forEach((d, i) =>{
                    //handle isNaN values
                    if((isNaN(d.price) || (!d.price && d.price !== 0))){
                        if(i > 0)d.price = parseFloat(data.data[i-1].price);
                        if(i === 0 && data.data.length > 1)d.price = parseFloat(data.data[i+1].price);
                    }
                    if(!isNaN(parseFloat(d.price))){
                        if(parseFloat(d.price) > 0)arr.push(parseFloat(d.price)*selectedVehicle.discountCoF);
                        if(parseFloat(d.price) === 0){
                            if(data.data.length > 1 && i > 0)arr.push(arr[i-1])
                            else arr.push(0);
                        }
                        xArr.push(`${shortMonth(parseInt(d.month))} ${d.year-2000}`);
                    }
                });
                setPriceSeries(arr);
                setXAxis(xArr);
                //setPriceSeries(arr.map((d)=>{return Math.round(d.price*selectedVehicle.discountCoF)}));
            })
            // eslint-disable-next-line
    }, [selectedVehicle]);

    const style = {
        position: 'absolute',
        top: '25%',
        left: '40%',
        transform: 'translate(-25%, -25%)',
        width: '700px',
        height: '50 vh',
        bgcolor: 'rgb(70, 70, 70)',
        border: '2px solid #FFF',
        boxShadow: 24,
        p: 4,
    };

    return(
        <>
            <Modal
                open={graphModalOpen}
                onClose={()=>{setGraphModalOpen(false)}}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <Typography id="modal-modal-title" variant="h6" component="h2">
                        Resale Price History
                    </Typography>
                    <LineChart 
                        xAxis={[{ data: xAxis, scaleType: 'band', }]}
                        series={[
                            {
                                data: priceSeries,
                                color: selectedVehicle ? Tableau10[1] : Tableau10[Math.floor(Math.random()*Tableau10.length)],
                                label: `${selectedVehicle.year} ${selectedVehicle.model}`
                            },
                        ]}
                        width={700}
                        height={350}
                        sx={{'& .MuiLineElement-root': {
                            strokeDasharray: '15 5',
                            strokeWidth: 6,
                          },
                        }}
                        slotProps={{
                            legend: {
                              direction: 'row',
                              position: { vertical: 'top', horizontal: 'right' },
                              padding: {right: 100}
                            },
                        }}
                    />
                    {selectedVehicle &&
                        <>
                            <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                                {selectedVehicle.year} {selectedVehicle.make} {selectedVehicle.model} 
                            </Typography>
                            <Typography id="modal-modal-description" sx={{ mt: 0 }}>
                                {selectedVehicle.condition} Condition | Average Miles
                            </Typography>
                        </>
                    }
                </Box>
            </Modal>
        </>
    );
}