import { createContext } from 'react';

export const UserContext = createContext({
    name: null,
    token: null,
    primaryFleetId: null,
    fleetVehicleCount: 0,
    setToken: Function,
    setPrimaryFleetId: Function,
    setFleetVehicleCount: Function,
    apiUrl: null,
    fleets: [],
});