import React, { useState } from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import PendingIcon from '@mui/icons-material/Pending';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import SuccessAlert from '../dashboard-components/SuccessAlert';


export default function WaitingListSignup(props) {
  const settings = require("../../settings.json");

  const URL_CREATE_MAIL_LIST_MEMBER = (settings.environment === 'production' || settings.environment === 'preproduction') ? `https://api.fleethelix.com/createMailingListMember` : `http://127.0.0.1:3333/createMailingListMember`; 

  const [emailError, setEmailError] = useState(false);
  const [successAlertOpen, setSuccessAlertOpen] = useState(false);
  // eslint-disable-next-line
  const isValidEmail = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g;

  const validateEmail = (email) => {
    if(email.match(isValidEmail)){
        setEmailError(false);
        return true;
    }else{
      setEmailError(true);
    }
    return false;
  }

  const handleSubmit = (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    if(!validateEmail(data.get('email')))return;
    const body = {
      email: data.get('email'),
    };
    const json = JSON.stringify(body);
    fetch(URL_CREATE_MAIL_LIST_MEMBER, {method: 'POST', body: json, headers:{"Content-Type": "application/json"}})
      .then((resp) => resp.json())
      .then((data) => {
        setSuccessAlertOpen(true)
      });

  };

  return (
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
            <PendingIcon />
          </Avatar>
          <Typography component="h1" variant="h5" align='center'>
            Beta Sign up
          </Typography>
          <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 3 }}>
            <Grid container width={300} spacing={0}>
              <Grid item xs={12} >
                <TextField
                  required
                  fullWidth
                  error={emailError}
                  id="email"
                  label="Email Address"
                  name="email"
                  autoComplete="email"
                />
              </Grid>
            </Grid>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
            >
              Sign Up For Free
            </Button>
          </Box>
        </Box>
        <SuccessAlert 
                open={successAlertOpen} 
                setOpen={setSuccessAlertOpen}
                message={"Your email has been added to our wait list."}
        />
      </Container>
  );
}