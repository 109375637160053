import React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import Grid from '@mui/material/Grid';
import StarIcon from '@mui/icons-material/StarBorder';
import Typography from '@mui/material/Typography';
import { Link as RouterLink } from "react-router-dom";
import Container from '@mui/material/Container';
import Copyright from '../Copyright';
import DashboardHeaderBar from './SiteHeaderBar';

const tiers = [
    {
        title: 'Free Tier',
        key: 1,
        price: '0',
        description: [
            'Free forever',
            '1 user included',
            '10 vehicles max',
            '3 Month Price Forecasts',
            'Email support',
            '.',
            '.',
            '.',
        ],
        buttonText: 'Sign up for free',
        href: "/signup",
        buttonVariant: 'contained',
        headerColor: 'rgb(80, 200, 255)',
    },
    {
        title: 'Starter',
        key: 0,
        price: '1',
        description: [
            '$1 per vehicle per month',
            '(Minimum billing of 5 vehicles)',
            '10 users included',
            '50 vehicle max',
            '6 Month Price Forecasts',
            'Priority email support',
            '.',
            '.',
        ],
        buttonText: 'Not Yet Available',
        buttonVariant: 'disabled',
    },
    {
        title: 'Pro',
        key: 2,
        price: '2',
        description: [
            '$2 per vehicle per month',
            '(Minimum billing of 5 vehicles)',
            'Unlimited users included',
            '500 vehicles',
            '12 Month Price Forecasts',
            'Priority email support',
            'Phone Support',
            'PDF and Excel Reports',
        ],
        buttonText: 'Not Yet Available',
        buttonVariant: 'disabled',
    },
];


export default function Pricing() {
  return (
    <Box >
      <DashboardHeaderBar />
      {/* Hero unit */}
      <Container disableGutters sx={{ py: 6, px: 2 }}>
        <Typography
          component="h1"
          variant="h2"
          align="center"
          color="text.primary"
          fontWeight={400}
        >
          Pricing
        </Typography>
        <Typography variant="h5" align="center" color="text.secondary" component="p">
          FleetHelix is currently in preview and only free pricing tiers are available.
        </Typography>
      </Container>
      {/* End hero unit */}
      <Container maxWidth="md" component="main">
        <Grid container spacing={5} alignItems="auto" >
          {tiers.map((tier) => (
            // Enterprise card is full width at sm breakpoint
            <Grid
              item
              key={tier.key}
              xs={12}
              sm={tier.title === 'Enterprise' ? 12 : 6}
              md={4}
            >
              <Card>
                <CardHeader
                  title={tier.title}
                  subheader={tier.subheader}
                  titleTypographyProps={{ align: 'center' }}
                  action={tier.title === 'Free Trial' ? <StarIcon /> : null}
                  subheaderTypographyProps={{
                    align: 'center',
                  }}
                  sx={{
                    backgroundColor: tier.headerColor ? tier.headerColor : (theme) =>
                      theme.palette.mode === 'light'
                        ? theme.palette.grey[200]
                        : theme.palette.grey[700],
                  }}
                />
                <CardContent>
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'baseline',
                      mb: 2,
                    }}
                  >
                    <Typography component="h2" variant="h3" color="text.primary">
                      ${tier.price}
                    </Typography>
                    <Typography variant="h6" color="text.secondary">
                      /mo
                    </Typography>
                  </Box>
                  <ul>
                    {tier.description.map((line) => (
                      <Typography
                        component="li"
                        variant="subtitle1"
                        align="center"
                        key={line}
                      >
                        {line}
                      </Typography>
                    ))}
                  </ul>
                </CardContent>
                <CardActions>
                    <Button fullWidth component={RouterLink} to={tier.href} variant={tier.buttonVariant}>
                      {tier.buttonText}
                    </Button>
                </CardActions>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Container>
      {/* Footer */}
      <Container
        maxWidth="md"
        component="footer"
        sx={{
          borderTop: (theme) => `1px solid ${theme.palette.divider}`,
          mt: 8,
          py: [3, 6],
        }}
      >
        <Grid container spacing={4} justifyContent="space-evenly">
          
        </Grid>
        <Copyright sx={{ mt: 5 }} />
      </Container>
      {/* End footer */}
    </Box>
  );
}