import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import './fleethelix.css';

//import reportWebVitals from './reportWebVitals';
import {createBrowserRouter, RouterProvider} from "react-router-dom";


import FleetHelix from './FleetHelix';
import ErrorPage from './components/ErrorPage';

import { PostHogProvider} from 'posthog-js/react';
const settings = require('./settings.json');
const options = {
  api_host: settings.postHogHost,
}

const router = createBrowserRouter([
  {
    path: "/*",
    element: <FleetHelix />,
    errorElement: <ErrorPage />,
  }
]);
const development = window.location.hostname === "localhost" ? true : false;
console.info('development environment:', development);
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <React.StrictMode>
        {(settings.environment === "production" || settings.environment === "preproduction") &&
            <PostHogProvider 
              apiKey={settings.postHogKey}
              options={options}
            >
                <RouterProvider router={router} />
            </PostHogProvider>
        }
        {(settings.environment === "development") &&
            <RouterProvider router={router} />
        }
    </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
//reportWebVitals(console.log);
