import React, { useState, useContext } from "react";
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import vinDecoder from "../../analytics/vinDecoder";
import SingleVinInput from "../dashboard-components/SingleVinInput";
import SingleYmmInput from "./SingleYmmInput";
import ImportVehicleInfoView from "./ImportVehicleInfoView";
import SuccessAlert from "./SuccessAlert";
import VinInputFailureAlert from "./VinInputFailureAlert";
import VinDecodeFailureAlert from "./VinDecodeFailureAlert";
import { UserContext } from "../../contexts/UserContext";

export default function ImportView(props){
    const {selectedFleetId} = props;
    const user = useContext(UserContext);
    const VEHICLE_CREATE_URL = `${user.apiUrl}createVehicle`;

    const [vin, setVin] = useState("");
    const [year, setYear] = useState("");
    const [make, setMake] = useState("");
    const [model, setModel] = useState("");
    const [trim, setTrim] = useState("");
    const [gvwr, setGVWR] = useState("");
    const [bodyClass, setBodyClass] = useState("");
    const [fuelTypePrimary, setFuelTypePrimary] = useState("");
    const [series, setSeries] = useState("");

    const [successAlertOpen, setSuccessAlertOpen] = useState(false);
    const [failureAlertOpen, setFailureAlertOpen] = useState(false);
    const [vinDecodeFailureAlertOpen, setVinDecodeFailureAlertOpen] = useState(false);

    const [inputByVin, setInputByVin] = useState(true);


    const decodeVinResponseHandler = (response) => {
        if(!response.ModelYear){
            setVinDecodeFailureAlertOpen(true);
            return;
        }
        if(response.ModelYear || response.Year === "")setYear(response.ModelYear);
        if(response.Make || response.Make === "")setMake(response.Make);
        if(response.Model || response.Model === "")setModel(response.Model);
        if(response.Trim || response.Trim === "")setTrim(response.Trim);
        if(response.BodyClass || response.Trim === "")setBodyClass(response.BodyClass);
        if(response.FuelTypePrimary || response.FuelTypePrimary === "")setFuelTypePrimary(response.FuelTypePrimary);
        if(response.Series || response.Series === "")setSeries(response.Series);
        if(response.GVWR){
            let g = response.GVWR.split('(');
            g = g[0].slice(0, -1)
            setGVWR(g);
        }
    }

    const handleReset = () => {
        setVin("");
        setYear("");
        setMake("");
        setModel("");
        setTrim("");
        setGVWR("");
        setBodyClass("");
        setFuelTypePrimary("");
        setSeries("");
    }

    const handleVINSubmit = (event) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);
        let vin = data.get('vin0').trim();
        setVin(vin);
        vinDecoder(vin, decodeVinResponseHandler);
    }

    const handleVehicleSubmit = (event) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);
        const t = data.get('trim');
        const s = data.get('series');
        if(!t && t !== "")data.append('trim', '');
        if(!s && s !== "")data.append('series', '');
        //headers:{"Content-Type": "multipart/form-data"}
        const object = {};
        data.forEach((value, key) => object[key] = value);
        object.fleetId = selectedFleetId;
        const json = JSON.stringify(object);
        fetch(VEHICLE_CREATE_URL, {method: 'POST', body: json, headers:{"Content-Type": "application/json", Authorization: `Bearer ${user.token}`}})
            .then((resp)=>resp.json())
            .then((data)=>{
                if(data.status === 200 && data.message === "success"){
                    setSuccessAlertOpen(true);
                    user.setFleetVehicleCount(user.fleetVehicleCount++);
                }
                else setFailureAlertOpen(true);
            });
    }

    return(
        <>
            <Grid container spacing={2} sx={{ height: '100vh' }} >
                <Grid item md={6} xs={12}>
                    <Grid item md={12} xs={12} display="flex">
                        <Grid item md={4} xs={12}>
                            <Button 
                                variant={inputByVin ? "contained" : "outlined"}
                                onClick={()=>{setInputByVin(true)}}
                                color="info"
                                fullWidth
                                sx={{ mt: 6, mb: 2, ml: 2}}
                            >
                                Input By VIN
                            </Button>
                        </Grid>
                        <Grid item md={4} xs={12}>
                            <Button 
                                variant={inputByVin ? "outlined" : "contained"}
                                onClick={()=>{setInputByVin(false)}}
                                color="info"
                                fullWidth
                                sx={{ mt: 6, mb: 1, ml: 2 }}
                            >
                                Input By YMM
                            </Button>
                        </Grid>
                    </Grid>
                    {!inputByVin &&
                        <SingleYmmInput 
                            year = {year} 
                            make = {make} 
                            model = {model} 
                            setVin = {setVin}
                            setYear = {setYear}
                            setMake = {setMake}
                            setModel= {setModel}
                        />
                    }
                    {inputByVin && 
                        <SingleVinInput 
                            vin = {vin} 
                            year = {year} 
                            make = {make} 
                            model = {model} 
                            setVin = {setVin}
                            handleReset = {handleReset}
                            handleVINSubmit={handleVINSubmit}
                        />
                    }
                </Grid>
                <Grid item md={6} xs={12}>
                    <ImportVehicleInfoView 
                        vin = {vin} 
                        year = {year} 
                        make = {make} 
                        model = {model} 
                        trim = {trim}
                        series = {series}
                        gvwr={gvwr}
                        bodyClass={bodyClass}
                        fuelTypePrimary={fuelTypePrimary}
                        setVin = {setVin}
                        setYear = {setYear}
                        setMake = {setMake}
                        setModel= {setModel}
                        setTrim={setTrim}
                        setGVWR={setGVWR} 
                        setBodyClass={setBodyClass}
                        setSeries = {setSeries}
                        setFuelTypePrimary={setFuelTypePrimary}
                        handleReset = {handleReset}
                        handleVehicleSubmit={handleVehicleSubmit}
                        inputByVin={inputByVin}
                    />
                </Grid>
            </Grid>
            <SuccessAlert 
                open={successAlertOpen} 
                setOpen={setSuccessAlertOpen}
                reset={handleReset}
                message={"The vehicle has been successfully added."}
            />
            <VinInputFailureAlert 
                open={failureAlertOpen}
                setOpen={setFailureAlertOpen}
            />
            <VinDecodeFailureAlert
                open={vinDecodeFailureAlertOpen}
                setOpen={setVinDecodeFailureAlertOpen}    
            />
        </>
    )
}