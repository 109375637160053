
const MIN_VIN_LENGTH = 8;//Standard VIN is 17 chars
const VIN_DECODER_URL = (vin) => {return `https://vpic.nhtsa.dot.gov/api/vehicles/decodevinvalues/${vin}?format=json`};


export default function vinDecoder(vin, responseHandler){
    vin = vin.toUpperCase().trim();
    //validate vin
    if(!_validate(vin)){
        if(responseHandler)responseHandler(_return_error('Invalid VIN'));
        return;
    }
    //decode vin
    _decode(vin, responseHandler);
}

const _decode = (vin, responseHandler) => {
    fetch(VIN_DECODER_URL(vin))
        .then((resp) => resp.json())
        .then((data) => {
            if(data.Count > 0 && data.Results.length > 0){
                if(responseHandler)responseHandler(data.Results[0]);
            }
        });
}

const _validate = (vin) => {
    if(typeof vin !== 'string')return false;
    if(vin.length < MIN_VIN_LENGTH)return false;
    if(vin.toLowerCase().indexOf('i') > 0)return false;
    if(vin.toLowerCase().indexOf('o') > 0)return false;
    if(vin.toLowerCase().indexOf('q') > 0)return false;
    return true;
}

const _return_error = (error) => {
    return({message: 'Invalid VIN Format', error: error});
}