import React, { useState, useEffect, useContext } from "react";
import { Typography } from "@mui/material";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import { LineChart } from "@mui/x-charts";
import { UserContext } from "../../contexts/UserContext";
import { shortMonth } from "../../utilities/utilities";

const Tableau10 = [
    '#4e79a7',
    '#f28e2c',
    '#e15759',
    '#76b7b2',
    '#59a14f',
    '#edc949',
    '#af7aa1',
    '#ff9da7',
    '#9c755f',
    '#bab0ab',
  ];



export default function MultiVehiclePriceGraphViewModal(props){
    const VEHICLE_COUNT_LIMIT = 10;
    const {selectedVehicles, graphModalOpen, setGraphModalOpen} = props;

    const [priceSeries, setPriceSeries] = useState([{data: [0,0],color: Tableau10[5],label:"NA"}]);
    const [xAxis, setXAxis] = useState([0,0]);

    const user = useContext(UserContext);

    const GET_VEHICLE_PRICE_HISTORY = `${user.apiUrl}getVehiclesPriceHistory`;
    useEffect(() => {
        const vehicles = selectedVehicles.slice(0, VEHICLE_COUNT_LIMIT);
        const promises = [];
        const series = [];
        const xArr = [];
        vehicles.forEach(((v) => {
            promises.push(fetch(`${GET_VEHICLE_PRICE_HISTORY}?vehicles=[${v.ymmIds}]`, {headers:{Authorization: `Bearer ${user.token}`}}).then((resp) => resp.json()));
        }))
        promises.forEach((p, idx)=>{
            p.then((data) => { 
                const arr = [];
                data.data.forEach((d, i)=> {
                    const discountCoF = vehicles[idx].discountCoF;
                    if((isNaN(d.price) || (!d.price && d.price !== 0))){
                        if(i > 0)d.price = parseFloat(data.data[i-1].price * discountCoF);
                        if(i === 0 && data.data.length > 1)d.price = parseFloat(data.data[i+1].price * discountCoF);
                    }
                    if(!isNaN(parseFloat(d.price))){
                        if(parseFloat(d.price) > 0)arr.push(parseFloat(d.price * discountCoF));
                        if(parseFloat(d.price) === 0){
                            if(data.data.length > 1 && i > 0)arr.push(arr[i-1])
                            else arr.push(0);
                        }
                        const l = `${shortMonth(parseInt(d.month))} ${d.year-2000}`;
                        if(xArr.indexOf(l) === -1)xArr.push(l);
                    }
                });
                let label = vehicles.length > 3 ? `${String(vehicles[idx].year).slice(-2)} ${vehicles[idx].model.slice(0, 10)}` : `${vehicles[idx].year} ${vehicles[idx].model}`;
                series.push({data: arr, color: Tableau10[idx], label: label})
            });
        });
        setXAxis(xArr);
        setPriceSeries(series);
        // eslint-disable-next-line
    }, [selectedVehicles])

//'rgb(78, 121, 180)'
    const style = {
        position: 'absolute',
        top: '25%',
        left: '40%',
        transform: 'translate(-25%, -25%)',
        width: '700px',
        height: '50 vh',
        bgcolor: 'rgb(70, 70, 70)',
        border: '2px solid #FFF',
        boxShadow: 24,
        p: 4,
    };
    return(
        <>
            <Modal
                open={graphModalOpen}
                onClose={()=>{setGraphModalOpen(false)}}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <Typography id="modal-modal-title" variant="h6" component="h2">
                        Resale Price History
                    </Typography>
                    <LineChart 
                        xAxis={[{ data: xAxis, scaleType: 'band', }]}
                        series={priceSeries}
                        width={700}
                        height={350}
                        sx={{'& .MuiLineElement-root': {
                            strokeDasharray: '15 5',
                            strokeWidth: 6,
                          },
                        }}
                        slotProps={{
                            legend: {
                              direction: 'row',
                              position: { vertical: 'top', horizontal: 'right' },
                              padding: {right: 100}
                            },
                        }}
                    />
                </Box>
            </Modal>
        </>
    );
}

/*
                    {selectedVehicle &&
                        <>
                            <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                                {selectedVehicle.year} {selectedVehicle.make} {selectedVehicle.model} 
                            </Typography>
                            <Typography id="modal-modal-description" sx={{ mt: 0 }}>
                                {selectedVehicle.condition} Condition | Average Miles
                            </Typography>
                        </>
                    }

*/