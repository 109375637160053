import React from 'react';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { Link } from '@mui/material';
import { Link as RouterLink } from "react-router-dom";
import Copyright from '../Copyright';
import DashboardHeaderBar from './SiteHeaderBar';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import QueryStatsIcon from '@mui/icons-material/QueryStats';
import IntegrationInstructionsIcon from '@mui/icons-material/IntegrationInstructions';

const cards = [
    {
        key: 0,
        icon: AttachMoneyIcon,
        iconColor: 'primary',
        title: 'Spot Price Snapshot',
        text: 'Discover and Document the Current Resale Value of Your Fleet.'
    },
    {
        key: 1,
        icon: QueryStatsIcon,
        iconColor: 'primary',
        title: 'Operate / Replace Breakevens',
        text: 'Data Driven Planning for CapEx Spend.'
    },
    {
        key: 2,
        icon: IntegrationInstructionsIcon,
        iconColor: 'primary',
        title: 'Integrations',
        text: `Consolidate Telematics and Fleet Management Data Streams. 
         Manage Your Data and Use it to Inform Fleet Financial Decisions.`
    }
];


export default function Album() {
  return (
    <>
        <DashboardHeaderBar />
      <main>
        {/* Hero unit */}
        <Box
          sx={{
            pt: 8,
            pb: 6,
          }}
        >
          <Container maxWidth="lg">
            <Typography
              component="h1"
              variant="h2"
              align="center"
              color="text.primary"
              gutterBottom
            >
              Financial Tools for Fleets.
            </Typography>
            <Typography variant="h5" align="center" color="text.secondary" paragraph>
              
            </Typography>
            <Stack
              sx={{ pt: 4 }}
              direction="row"
              spacing={2}
              justifyContent="center"
            >
              <Link component={RouterLink} to="/signup"><Button size="large" variant="contained">Sign Up For Free</Button></Link>
            </Stack>
          </Container>
        </Box>
        <Container sx={{ py: 2 }} maxWidth="md">
          {/* End hero unit */}
          <Grid container spacing={6}>
            {cards.map((card) => (
              <Grid item key={card.key} xs={12} sm={6} md={4}>
                <Card
                  sx={{  height: '100%', display: 'flex', flexDirection: 'column' }}
                >
                  <CardContent sx={{ flexGrow: 1 }}>
                    <div style={{marginTop: '0rem', marginBottom: '1rem', display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                        <card.icon color="success" sx={{ fontSize: 80 }}/>
                    </div>
                    <Typography gutterBottom variant="h5" component="h2">
                      {card.title}
                    </Typography>
                    <Typography>
                      {card.text}
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
            ))}
          </Grid>
        </Container>
      </main>
      {/* Footer */}
      <Box sx={{ bgColor: 'rgb(60,60,60)', p: 6 }} component="footer">
        <Copyright />
      </Box>
      {/* End footer */}
    </>
  );
}