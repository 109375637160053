import React from "react";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemIcon from "@mui/material/ListItemIcon";
import Star from "@mui/icons-material/Star";
import Link from "@mui/material/Link";
import { Link as RouterLink } from "react-router-dom";


export default function IntgerationView(props){


    return(
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <Typography variant={"h4"}>Integrations are currently unavailable.</Typography>
            </Grid>
            <Grid item xs={12} md={8}>
                <Typography variant={"h6"}>
                    We're working to bring as many on as we can as quickly as we can, 
                    starting with telematics providers and following with other 
                    fleet management information systems providers. Integrations will be made available to paid subscriptions first.
                </Typography>
                <br/>
                <Typography variant={"h6"} paragraph>Our proposed order of Integrations follows:</Typography>
                <List>
                    <ListItem>
                        <ListItemIcon> <Star /></ListItemIcon>
                        <ListItemText variant={"h6"}>Geotab</ListItemText>
                    </ListItem>
                    <ListItem>
                    <ListItemIcon> <Star /></ListItemIcon>
                        <ListItemText>Samsara</ListItemText>
                    </ListItem>
                    <ListItem>
                        <ListItemIcon> <Star /></ListItemIcon>
                        <ListItemText>Verizon Connect</ListItemText>
                    </ListItem>
                    <ListItem>
                        <ListItemIcon> <Star /></ListItemIcon>
                        <ListItemText>GPS Insights</ListItemText>
                    </ListItem>
                    <ListItem>
                        <ListItemIcon> <Star /></ListItemIcon>
                        <ListItemText>AssetWorks</ListItemText>
                    </ListItem>
                </List>
                <Grid item>
                    <Typography ml={4} variant={"h6"}>
                        Have Suggestions or Comments? Get in touch and... {" "}
                        <Link
                        component={RouterLink} 
                        color="inherit" 
                        to="/contact"
                    >
                        contact us.
                    </Link>
                    </Typography>
                </Grid>
                <br/>
                <br/>
                <br/>
                <br/>
                <br/>
            </Grid>
        </Grid>
    );
}