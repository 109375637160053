import React, {useState, useEffect, useContext} from "react";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Paper from '@mui/material/Paper';
import CssBaseline from '@mui/material/CssBaseline';
import Button from "@mui/material/Button";
import { DataGrid, useGridApiRef } from '@mui/x-data-grid';
import { Typography } from "@mui/material";
import { UserContext } from "../../contexts/UserContext";
import SingleVehiclePriceGraphViewModal from "./SingleVehiclePriceGraphViewModal";
import MultiVehiclePriceGraphViewModal from "./MultiVehiclePriceGraphViewModal";


export default function PortfolioView(props){
    const {selectedFleetId} = props;
    const apiRef = useGridApiRef();
    const [vehicles, setVehicles] = useState([]);
    const [selectedVehicleIds, setSelectedVehicleIds] = useState([]);
    const [conditions, setConditions] = useState(null);
    const [fleetSpotPrice, setFleetSpotPrice] = useState(0);
    const [fleetPrice30, setFleetPrice30] = useState(0);
    const [fleetPrice180, setFleetPrice180] = useState(0);
    const [fleetPrice365, setFleetPrice365] = useState(0);
    const [selectedVehicles, setSelectedVehicles] = useState(null);
    const [graphModalOpen, setGraphModalOpen] = useState(false);

    const user = useContext(UserContext);
    const VEHICLES_GET_URL = `${user.apiUrl}getVehicles/`;
    const GET_VEHICLE_CONDITIONS = `${user.apiUrl}getVehicleConditionsList/`;

    useEffect(() => {
        fetch(GET_VEHICLE_CONDITIONS, {headers:{Authorization: `Bearer ${user.token}`}})
            .then((resp) => resp.json())
            .then((data) => {
                setConditions(data.data);
            })
            .catch((err) => console.error(err));
        // eslint-disable-next-line
    },[]);

    useEffect(() => {
        const arr = vehicles.filter((v) => selectedVehicleIds.indexOf(v.id) > -1);
        setSelectedVehicles(arr);
        // eslint-disable-next-line
    }, [selectedVehicleIds]);

    const spotPriceCellFormatter = (cell) => {
        let c = "white";
        return (
            <span>
                <span style={{color: c}}>${cell}</span>
            </span>
        );
    }

    const trendCellFormatter = (cell) => {
        let c = "green";
        let s = '\u0394';
        if(cell.includes('-')){
            c = parseFloat(cell) > -10 ? "white" : "red";
            s = '\u2207';
        }
        if(cell.includes('N/A')){
            c="white";
            s="";
        }

        return (
            <span>
                <span style={{color: c, fontWeight: 800}}>{cell}{s}</span>
            </span>
        );
    };

    /*
    const priceHistoryCellRenderer = (cell) => {

        return(
            <Button sx={{width: "4rem", bgcolor:"rgba(80, 200, 255, 1)"}}
                variant={"contained"}
                color="info"
                onClick={()=>{setSelectedVehicle(cell.row);setGraphModalOpen(true);}}
            >
                Open
            </Button>
        )
                { key: -2, 
            field: multiSelect ? 'space' : 'history',
            headerName: multiSelect ? '' : 'Price History', 
            width: multiSelect ? 1 : 120,
            editable: false,
            renderCell: multiSelect ? ()=>{return(<span/>)} : (params) => {return priceHistoryCellRenderer(params)},
        },
    } 
    */
    const columns = [
        { key: -1, field: 'space', headerName: '', width: 1, editable: false},
        { key: 0, field: 'asset-vin', headerName: 'Asset/VIN', width: 150, editable: false },
        { key: 1, field: 'spotPrice', headerName: 'Spot Price', width: 120, editable: false, renderCell: (params) => {return spotPriceCellFormatter(params.value);}, },
        { key: 2, 
            field: 'trend30', 
            headerName: '30 Day \u00A0', 
            width: 120, 
            editable: false,     
            renderCell: (params) => {return trendCellFormatter(params.value);},
        },
        { key: 3, field: 'trend180', headerName: '6 Month \u00A0', width: 120, editable: false, renderCell: (params) => {return trendCellFormatter(params.value);},},
        { key: 4, field: 'trend365', headerName: 'Yearly \u00A0', width: 120, editable: false, renderCell: (params) => {return trendCellFormatter(params.value);}, },
        { key: 5, field: 'coverageRating', headerName: 'Coverage Rating', width: 120, editable: false },
        { key: 6, field: 'condition', headerName: 'Condition', width: 100, editable: false },
        { key: 7, field: 'year', headerName: 'Year', width: 100, editable: false },
        { key: 8, field: 'make', headerName: 'Make', width: 150, editable: false }, 
        { key: 9, field: 'model', headerName: 'Model', width: 150, editable: false }, 
        { key: 10, field: 'trim-series', headerName: 'Trim/Series', width: 150, editable: false },       
    ];

    const formatVehicle = (vehicle) => {
        const v = vehicle;
        const condition = conditions.find((c) => c.name === v.condition);
        v.discountCoF = condition ? 1-(condition.discount/100) : 1;
        v.id = v.pkid;
        v['asset-vin'] = v.asset ? v.asset : v.vin;
        v['trim-series'] = v.trim;
        if(v.trim && v.series)v['trim-series'] = `${v.trim}/${v.series}`;
        else if(!v.trim && v.series)v['trim-series'] = v.series;
        v.spotPrice = `${Math.round(parseFloat(v.currentPrice))}`;
        v.spotPrice = (v.discountCoF * v.spotPrice).toFixed(0);
        v.price30 = v.discountCoF * parseFloat(v.price30);
        v.price180 = v.discountCoF * parseFloat(v.price180);
        v.price365 = v.discountCoF * parseFloat(v.price365);
        
        //vehicle change vals
        const change30 = (100*((v.spotPrice - v.price30)/v.price30)).toFixed(1);
        const change180 = (100*((v.spotPrice - v.price180)/v.price180)).toFixed(1);
        const change365 = (100*((v.spotPrice - v.price365)/v.price365)).toFixed(1);

        v.trend30 = `${isNaN(change30) ? 'N/A' : `${change30}%`}`;
        v.trend180 = `${isNaN(change180) ? 'N/A' : `${change180}%`}`;
        v.trend365 = `${isNaN(change365) ? 'N/A' : `${change365}%`}`;
        v.coverageRating = v.priceConfidence/10;
        return v;
    }

    useEffect(()=>{
        //get vehicles on load
        if(!selectedFleetId || !conditions)return;
        fetch(`${VEHICLES_GET_URL}?fleetId=${selectedFleetId}`, {headers:{Authorization: `Bearer ${user.token}`}})
            .then((resp) => resp.json())
            .then((data) => {
                const arr = [];
                if(data.data)data.data.forEach((v)=>{
                    arr.push(formatVehicle(v));
                });
                setVehicles(arr);
            })
            .catch((err)=>console.error(err));
            // eslint-disable-next-line
    },[selectedFleetId, conditions]);


    useEffect(() => {
        //const updateAvg = (a0, v, n) => {
        //    return(avgOld+((val-avgOld)/(n+1)))
        //}
        let fleetSpotPrice = 0;
        let fleetPrice30 = 0;
        let fleetPrice180 = 0;
        let fleetPrice365 = 0;
        let change365 = 0;
        let avgSize = 0;
        vehicles.forEach((v) => {
            fleetSpotPrice += parseFloat(v.spotPrice);
            fleetPrice30 += parseFloat(v.price30);
            fleetPrice180 += parseFloat(v.price180);
            if(!isNaN(parseFloat(v.price365))){
                fleetPrice365 += parseFloat(v.price365);
                change365 = (change365, v.price365, avgSize);
            }
        });
        setFleetSpotPrice(fleetSpotPrice.toFixed(0));
        setFleetPrice30(fleetPrice30.toFixed(0));
        setFleetPrice180(fleetPrice180.toFixed(0));
        setFleetPrice365(fleetPrice365.toFixed(0));
    }, [vehicles])
    const now = new Date();

    const formatedDelta = (spotPrice, compPrice) => {
        const sp = parseFloat(spotPrice);
        const cp = parseFloat(compPrice);
        const fd = (100*((sp - cp)/cp));
        return isNaN(fd) ? 'NA' : `${fd.toFixed(1)}%`;
    }

    const deltaColor = (spotPrice, compPrice) => {
        const sp = parseFloat(spotPrice);
        const cp = parseFloat(compPrice);
        const fd = (100*((sp - cp)/cp));
        if(fd > 0)return "green";
        if(fd === 0 || isNaN(fd) || !isFinite(fd))return "white";
        return "red";   
    }

    return(
        <>
            <CssBaseline />
            <Grid container component={Paper}>
                <Grid item xs={12} md={6} ml={6}>
                <Typography sx={{textDecoration: 'none'}} color="white" variant="h6">{now.getMonth()+1}/{now.getDate()}/{now.getFullYear()} Vehicle Resale Market Prices </Typography>
                </Grid>
                <Grid item xs={12} ml={10}>
                    <Typography color="white" variant="h6">Total Vehicle Resale Value: ${fleetSpotPrice} </Typography>
                    <Grid container>
                        <Grid item xs={6} display={"flex"} justifyContent={"flex-start"}>
                            <Typography color={deltaColor(fleetSpotPrice, fleetPrice30)} variant="h6">30 Day Change: {formatedDelta(fleetSpotPrice, fleetPrice30)}</Typography>
                        </Grid>
                    </Grid>
                    <Grid container>
                        <Grid item xs={6} display={"flex"} justifyContent={"flex-start"}>
                            <Typography color={deltaColor(fleetSpotPrice, fleetPrice180)} variant="h6">6 Month Change: {formatedDelta(fleetSpotPrice, fleetPrice180)} </Typography>
                        </Grid>
                    </Grid>
                    <Grid container>
                        <Grid item xs={6} display={"flex"} justifyContent={"flex-start"}>
                            <Typography color={deltaColor(fleetSpotPrice, fleetPrice365)} variant="h6">Yearly Change: {formatedDelta(fleetSpotPrice, fleetPrice365)}</Typography>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12} display={"flex"} justifyContent={"flex-end"} mr={5} mb={1}>
                    <Button sx={{width: "16rem", bgcolor:"rgba(80, 200, 255, 1)"}}
                        variant={"contained"}
                        color="info"
                        onClick={()=>{if(selectedVehicles.length > 0)setGraphModalOpen(true)}}
                    >
                        Open History
                    </Button>
                </Grid>

                <Grid item xs={12}>
                    <Box style={{ height: '85vh', width: '80vw'}}>
                        {(vehicles && columns.length > 0) && 
                        <DataGrid 
                            apiRef={apiRef}
                            initialState={{
                                sorting: {
                                  sortModel: [{ field: 'asset-vin', sort: 'asc' }],
                                },
                              }}
                            columns={columns}
                            rows={vehicles}
                            checkboxSelection
                            onRowSelectionModelChange={(m)=>setSelectedVehicleIds(m)}
                            localeText={{ noRowsLabel: "You have no vehicles. Use the import or integrations sections to add some." }} 
                        />
                        }
                    </Box>
                </Grid>
            </Grid>
            {selectedVehicles?.length === 1 &&
                <SingleVehiclePriceGraphViewModal selectedVehicle={selectedVehicles[0]} graphModalOpen={graphModalOpen} setGraphModalOpen={setGraphModalOpen} />}
            {selectedVehicles?.length > 1 &&
                <MultiVehiclePriceGraphViewModal selectedVehicles={selectedVehicles} graphModalOpen={graphModalOpen} setGraphModalOpen={setGraphModalOpen} />}
        </>
    )
}