import React from "react";
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Button from '@mui/material/Button';
import Link from '@mui/material/Link';
import { Link as RouterLink } from "react-router-dom";
import Typography from '@mui/material/Typography';

export default function SiteHeaderBar(props){
    return(
        <AppBar
            position="static"
            color="default"
            elevation={0}
            sx={{ backgroundColor: 'rgb(60,65,65)', borderBottom: (theme) => `1px solid ${theme.palette.divider}` }}
        >
            <Toolbar sx={{ flexWrap: 'wrap' }}>
                <Typography variant="h6" color="inherit" noWrap sx={{ flexGrow: 1 }}>
                    FleetHelix    
                </Typography>
                <nav>
                    <Link
                        component={RouterLink}
                        variant="button"
                        color="text.primary"
                        to="/"
                        sx={{ my: 1, mx: 1.5 }}
                    >
                        Home
                    </Link>
                    {/*<Link
                        component={RouterLink}
                        variant="button"
                        color="text.primary"
                        to="/features"
                        sx={{ my: 1, mx: 1.5 }}
                    >
                        Features
                    </Link>*/}
                    <Link
                        component={RouterLink}
                        variant="button"
                        color="text.primary"
                        to="/pricing"
                        sx={{ my: 1, mx: 1.5 }}
                    >
                        Pricing
                    </Link>
                    <Link
                        component={RouterLink}
                        variant="button"
                        color="text.primary"
                        to="/contact"
                        sx={{ my: 1, mx: 1.5 }}
                    >
                        Contact
                    </Link>
                </nav>
                <Button 
                    component={RouterLink}
                    to="/signin" 
                    variant="outlined" 
                    sx={{ my: 1, mx: 1.5 }}
                >
                    Login
                </Button>
            </Toolbar>
        </AppBar>
    )
}