import React from "react";
import { Grid } from "@mui/material";
import { DataGrid, useGridApiRef } from '@mui/x-data-grid';
import { Button } from "@mui/material";


export default function FleetView(props){
    const {fleets} = props;
    const apiRef = useGridApiRef();
    const columns = [
        { key: 0, field: 'id', headerName: 'Fleet Id', width: 150, editable: false },
        { key: 1, field: 'name', headerName: 'Name', width: 150, editable: false },
        { key: 2, field: 'vehicle_count', headerName: 'Vehicle Count', width: 150, editable: false },
       
    ];

    return(
        <>
            Fleet View/Group View
            <Grid container>
                <Grid item xs={12}>
                    <Button
                        variant="contained"
                        color="info"
                        sx={{ml:2, my:2}}
                    >
                        Add Fleet
                    </Button>
                    {fleets && <DataGrid 
                        apiRef={apiRef}
                        initialState={{
                            sorting: {
                                sortModel: [{ field: 'id', sort: 'asc' }],
                            },
                        }}
                        columns={columns}
                        rows={fleets}
                        localeText={{ noRowsLabel: "You have no vehicles. Use the import or integrations sections to add some." }} 
                    />}
                </Grid>

            </Grid>

        </>
    );
}