import React from "react";
import Box from "@mui/material/Box";
import Button from '@mui/material/Button';
import TextField from "@mui/material/TextField";


export default function SingleVinInput(props){

    const {
        vin, 
        year, 
        make, 
        model, 
        setVin, 
        handleReset, 
        handleVINSubmit,
    } = props;

    return(
        <>
            <Box 
                component="form"
                display={'flex'}
                onSubmit={handleVINSubmit}
                flexDirection={'column'} 
                margin={3} 
                height={'60vh'}>
                Single VIN Input
                <p>(Enter or Copy/Paste a VIN)</p>
                <p>You Can Import VINs from a Telematics Provider or a Fleet Management System in the Integrations Panel</p>
                {/*<p>TESTVIN: 1C4RJFAG1CC223766</p>*/}
                <TextField
                    margin="normal"
                    id="vin0"
                    value={vin}
                    onChange={(e)=>setVin(e.target.value.toUpperCase())}
                    label="VIN"
                    name="vin0"
                    placeholder="Enter a VIN"   
                />
                {(!vin || !year || !make || !model) &&
                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        sx={{ mt: 3, mb: 2 }}
                    >
                        Submit
                    </Button>
                }
                {(vin && year && make && model) &&
                    <Button
                        type="reset"
                        fullWidth
                        variant="outlined"
                        color="info"
                        onClick={handleReset}
                        sx={{mt: 3, mb: 2 }}
                    >
                        Reset
                    </Button>
                }
            </Box>
        </>
    )
}