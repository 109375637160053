import React, { useState, useEffect, useContext } from 'react';
import Button from '@mui/material/Button';
import SingleYmmInput from '../dashboard-components/SingleYmmInput';
import priceForecast from "../../analytics/priceForecast";
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import CircularProgress from '@mui/material/CircularProgress';
import { LineChart } from "@mui/x-charts";
import { UserContext } from '../../contexts/UserContext';
import { shortMonth } from "../../utilities/utilities";

const Tableau10 = [
  '#4e79a7',
  '#f28e2c',
  '#e15759',
  '#76b7b2',
  '#59a14f',
  '#edc949',
  '#af7aa1',
  '#ff9da7',
  '#9c755f',
  '#bab0ab',
];

export default function TryItSide(props) {
  const user = useContext(UserContext);  

  const [priceSeries, setPriceSeries] = useState([0,0]);
  const [xAxis, setXAxis] = useState([0,0]);
  const [minPrice, setMinPrice] = useState(0);
  const [maxPrice, setMaxPrice] = useState(0);
  const [selectedVehicle, setSelectedVehicle] = useState(null);
  const [priceForecasts, setPriceForecasts] = useState(null);
  const [year, setYear] = useState(2019);
  const [make, setMake] = useState("Mercedes");
  const [model, setModel] = useState("Sprinter");
  const [update, setUpdate] = useState(true);
  const [progress, setProgress] = useState(0);

  //const user = useContext(UserContext);

  const INCLUDED_HISTORY_MONTHS = 2;
  const INCLUDED_FORECAST_MONTHS = 6;
  const PRICE_HEADROOM = 1000;
  const SAMPLE_VEHICLE_URL = `${user.apiUrl}getSampleVehicle`

  const demoToken = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyIjoidGVzdEBmbGVldGhlbGl4LmNvbSIsImlhdCI6MTcwNTYxNTg5Nn0.dmp6Wvo19fXejith5hoUrSd-vDhQKaEeYCnFNVAyGlg";

  const handleSubmit = (event) => {
    event.preventDefault();
    console.log(year, make, model);
    setProgress(0);
    setUpdate(true);
  }

  user.token = demoToken;
  
  useEffect(() => {
      if(progress < 100)setTimeout(() => setProgress(progress + 10), 220);
  }, [progress]);


  useEffect(()=>{
    if(!update)return;
    const sampleVehicle = {
      id: 0,
      year: year,
      make: make,
      model: model,
      ymmIds: []
    }
    const url = `${SAMPLE_VEHICLE_URL}?year=${year}&make=${make}&model=${model}`;
    fetch(url, {headers: {Authorization: `Bearer ${user.token}`}})
      .then((resp) => resp.json())
      .then((data) => {
        sampleVehicle.ymmIds = data.data;
        setSelectedVehicle(formatVehicle(sampleVehicle));
        setUpdate(false);
      });
      // eslint-disable-next-line
  }, [year, make, model, user, update]);

  useEffect(() => {
    if(selectedVehicle)priceForecast({apiUrl: user.apiUrl, user: user}, [selectedVehicle], (res)=>{setPriceForecasts(res)});
  }, [selectedVehicle, user])

  const formatVehicle = (vehicle) => {
    const v = vehicle;
    v.condition = "Average"
    v.discountCoF = 1.03; //fuzz the prices a bit
    v.coverageRating = 75; //just setting for demo
    /*
    v.spotPrice = `${Math.round(parseFloat(v.currentPrice))}`;
    v.spotPrice = (v.discountCoF * v.spotPrice).toFixed(0);
    v.price30 = v.discountCoF * parseFloat(v.price30);
    v.price180 = v.discountCoF * parseFloat(v.price180);
    v.price365 = v.discountCoF * parseFloat(v.price365);
    
    //vehicle change vals
    const change30 = (100*((v.spotPrice - v.price30)/v.price30)).toFixed(1);
    const change180 = (100*((v.spotPrice - v.price180)/v.price180)).toFixed(1);
    const change365 = (100*((v.spotPrice - v.price365)/v.price365)).toFixed(1);

    v.trend30 = `${isNaN(change30) ? 'N/A' : `${change30}%`}`;
    v.trend180 = `${isNaN(change180) ? 'N/A' : `${change180}%`}`;
    v.trend365 = `${isNaN(change365) ? 'N/A' : `${change365}%`}`;
    
    v.coverageRating = v.priceConfidence/10;
    */
    return v;
}

  useEffect(() => {
      if(!selectedVehicle || !priceForecasts || priceForecasts.length < 1)return;
      const v = selectedVehicle;

      const pf = priceForecasts.find((f) => f.id === v.id);
      if(!pf)return;
      //vehicle change vals
      const change30 = (100*(((v.discountCoF * pf.twelveMonthForecast[0]) - v.spotPrice)/v.spotPrice)).toFixed(1);
      const change90 = (100*(((v.discountCoF * pf.twelveMonthForecast[2]) - v.spotPrice)/v.spotPrice)).toFixed(1);
      const change180 = (100*(((v.discountCoF * pf.twelveMonthForecast[5]) - v.spotPrice)/v.spotPrice)).toFixed(1);
      const change365 = (100*(((v.discountCoF * pf.twelveMonthForecast[11]) - v.spotPrice)/v.spotPrice)).toFixed(1);

      v.forecast30 = `${isNaN(change30) ? 'N/A' : `${change30}%`}`;
      v.forecast90 = `${isNaN(change30) ? 'N/A' : `${change90}%`}`;
      v.forecast180 = `${isNaN(change30) ? 'N/A' : `${change180}%`}`;
      v.forecast365 = `${isNaN(change30) ? 'N/A' : `${change365}%`}`;
      //free tier hobble
      //v.forecast180 = "N/A";
      v.forecast365 = "N/A";

  }, [priceForecasts, selectedVehicle]);

  useEffect(() => {
      //get xAxis vals
      if(!priceForecasts || !selectedVehicle)return;
      const xAxis = [];
      const pf = priceForecasts.find((p) => p.id === selectedVehicle.id);
      const ph = pf.priceHistory.slice(pf.priceHistory.length-INCLUDED_HISTORY_MONTHS);
      let month = 0;
      let year = 0;
      ph.forEach((p) => {
          month = parseInt(p.month);
          year = parseInt(p.year);
          xAxis.push(`${shortMonth(month)} ${year-2000}`);
      });
      pf.twelveMonthForecast.slice(0, INCLUDED_FORECAST_MONTHS).forEach((p) => {
          month++;
          if(month > 12){
              month = 1;
              year++;
          }
          xAxis.push(`${shortMonth(month)} ${year-2000}`);
      });
      setXAxis(xAxis);
    
  }, [priceForecasts, selectedVehicle]);

  useEffect(() => {
    //set priceforecast
    if(!priceForecasts || !selectedVehicle)return;
    const combo = Array(INCLUDED_FORECAST_MONTHS+INCLUDED_HISTORY_MONTHS).fill(null);
    const forecast = Array(INCLUDED_FORECAST_MONTHS+INCLUDED_HISTORY_MONTHS).fill(null);
    const history = Array(INCLUDED_FORECAST_MONTHS+INCLUDED_HISTORY_MONTHS).fill(null);
    const pf = priceForecasts.find((p) => p.id === selectedVehicle.id);
    let min = null;
    let max = null;

    pf.priceHistory.slice(pf.priceHistory.length-INCLUDED_HISTORY_MONTHS).map((p) => parseInt(p.price)).forEach((m,i) => {
        m = Math.round(m * selectedVehicle.discountCoF);
        m = Math.round(m / 50)*50
        if(i===0){
            min = m;
            max = m;
        }
        history[i] = m;
        combo[i] = m;
        if(m){
            min = Math.min(m, min);
            max = Math.max(m, max);
        }
    });
    forecast[INCLUDED_HISTORY_MONTHS-1] = history[INCLUDED_HISTORY_MONTHS-1];
    pf.twelveMonthForecast.slice(0, INCLUDED_FORECAST_MONTHS).forEach((m, i) => {
        m = Math.round(m * selectedVehicle.discountCoF);
        m = Math.round(m / 50)*50
        forecast[i+INCLUDED_HISTORY_MONTHS] = m;
        combo[i+INCLUDED_HISTORY_MONTHS] = m;
        if(m){
            min = Math.min(m, min);
            max = Math.max(m, max);
        }
    });
    
    const series = [
        {
            data: combo,
            color: selectedVehicle ? Tableau10[4] : Tableau10[Math.floor(Math.random()*Tableau10.length)],
            label: `${selectedVehicle.year} ${selectedVehicle.model}`
        }
    ];

    setMinPrice(min-PRICE_HEADROOM);
    setMaxPrice(max+PRICE_HEADROOM);
    setPriceSeries(series);

  },[priceForecasts, selectedVehicle])

  const style = {
      width: '485px',
      height: '450px',
      bgcolor: 'rgb(70, 70, 70)',
      border: '2px solid #FFF',
      boxShadow: 24,
      p: 4,
  };

  if(progress < 100 || !selectedVehicle || !year || !make || !model || !priceSeries || !priceSeries[0].data || priceSeries[0].data.length < 1){
    return(
        <Container maxWidth="xs" sx={{ mx: 2, mt: 8 }} align="center">
            <Typography id="modal-modal-title" variant="h6" component="h2">
                {`Try our vehicle price forecast`}
            </Typography>
            <CircularProgress sx={{mt:12}}variant="determinate" value={progress} color="warning"/>
        </Container>
    );
  };
  
  return (
      <Container maxWidth="xs" sx={{ mx: 2, mt: 8 }} align="center">
          <Typography id="modal-modal-title" variant="h6" component="h2">
              {`Try our vehicle price forecast`}
          </Typography>
          <Box sx={style}>
              <Typography id="modal-modal-title" variant="h6" component="h2">
                  {`${INCLUDED_FORECAST_MONTHS} Month Resale Price Forecast`}
              </Typography>
              {xAxis && priceSeries && <LineChart 
                  xAxis={[{ data: xAxis, scaleType: 'band', }]}
                  yAxis={[{min: minPrice, max: maxPrice}]}
                  series={priceSeries}
                  
                  width={450}
                  height={300}
                  sx={{'& .MuiLineElement-root': {
                      strokeDasharray: '15 5',
                      strokeWidth: 6,
                    },
                  }}
                  slotProps={{
                      legend: {
                        direction: 'row',
                        position: { vertical: 'top', horizontal: 'right' },
                        padding: {right: 100}
                      },
                  }}
              />}
              {selectedVehicle &&
                  <>
                      <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                          {selectedVehicle.year} {selectedVehicle.make} {selectedVehicle.model} 
                      </Typography>
                      <Typography id="modal-modal-description" sx={{ mt: 0 }}>
                          {selectedVehicle.condition} Condition | Average Miles
                      </Typography>
                  </>
              }
          </Box>
        {true &&
        <Box align="left">
            <SingleYmmInput
              isTeaser={true}
              year={year}
              make={make}
              model={model}
              setYear={setYear}
              setMake={setMake}
              setModel={setModel}
            />
            <Button color="info" 
              variant="contained" 
              sx={{ml:2, mt: 2}}
              onClick={handleSubmit}
            >
              Forecast
            </Button>
          </Box>
        }
      </Container>
  );
}