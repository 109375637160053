import React, {useState, useEffect, useContext} from "react";
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Box from "@mui/material/Box";
import { DataGrid, useGridApiRef } from '@mui/x-data-grid';
import { Link as RouterLink } from "react-router-dom";
import { UserContext } from '../../contexts/UserContext';

const METERS2MILES = (meters) => {return meters * 0.000621371 }

export default function VehicleStatsView(props){
    const {selectedFleetId} = props;
    const apiRef = useGridApiRef();

    const [vehicles, setVehicles] = useState([]);

    const user = useContext(UserContext);
    const VEHICLES_GET_URL = `${user.apiUrl}getVehicles/`;

    const columns = [
        { key: 0, field: 'asset-vin', headerName: 'Asset/VIN', width: 150, editable: false },
        { key: 1, field: 'condition', headerName: 'Condition', width: 150, editable: false },
        { key: 2, field: 'odometer', headerName: 'Odometer', width: 150, editable: false },
        { key: 3, field: 'engineHours', headerName: 'Engine Hours', width: 150, editable: false },
        { key: 4, field: 'year', headerName: 'Year', width: 100, editable: false },
        { key: 5, field: 'make', headerName: 'Make', width: 150, editable: false }, 
        { key: 6, field: 'model', headerName: 'Model', width: 150, editable: false }, 
        { key: 7, field: 'trim-series', headerName: 'Trim/Series', width: 150, editable: false },       
    ];

    useEffect(()=>{
        //get vehicles on load
        fetch(`${VEHICLES_GET_URL}?fleetId=${selectedFleetId}`, {headers:{Authorization: `Bearer ${user.token}`}})
            .then((resp) => resp.json())
            .then((data) => {
                if(data.data)data.data.forEach((v)=>{
                    v.id = v.pkid;
                    v['asset-vin'] = v.asset ? v.asset : v.vin;
                    v['trim-series'] = v.trim;
                    v.odometer = v.odometerMeters ? METERS2MILES(v.odometerMeters) : "N/A";
                    v.engineHours = v.engineHours ? v.engineHours : "N/A";
                    if(v.trim && v.series)v['trim-series'] = `${v.trim}/${v.series}`;
                    else if(!v.trim && v.series)v['trim-series'] = v.series;
                });
                setVehicles(data.data);
            })
            .catch((err)=>console.error(err));
            // eslint-disable-next-line
    },[selectedFleetId]);

    return(
        <>
            <Grid container>
                <Grid item xs={12} md={6}>
                    View and update vehicle usage values and measurements.
                </Grid>
                <Grid item xs={12} md={5} display={"none"}>
                    <p style={{fontSize:"10pt"}}>Odometer and Engine Hour Columns Require Telematics or FMS Integrations.</p>
                </Grid>
                <Grid item xs={12} display={"flex"} padding={1} mr={5}>
                    <Grid item xs={12} md={2} ml={1}>
                        <Button 
                            variant="outlined"
                            disabled={false}
                            color={"success"}
                            component={RouterLink}
                            to="/dashboard/vehicles"
                            sx={{width: "75%"}}
                        >
                            Vehicles
                        </Button>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <Box style={{ height: '85vh', width: '80vw'}}>
                        {(vehicles && columns.length > 0) && 
                        <DataGrid 
                            apiRef={apiRef}
                            initialState={{
                                sorting: {
                                  sortModel: [{ field: 'asset-vin', sort: 'asc' }],
                                },
                              }}
                            columns={columns}
                            rows={vehicles}
                            localeText={{ noRowsLabel: "You have no vehicles. Use the import or integrations sections to add some." }} 
                        />
                        }
                    </Box>
                </Grid>
            </Grid>
        </>
    )

}