import React from 'react';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import Paper from '@mui/material/Paper';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import InsightsIcon from '@mui/icons-material/Insights';
import PostAddIcon from '@mui/icons-material/PostAdd';
import IntegrationInstructionsIcon from '@mui/icons-material/IntegrationInstructions';
import SettingsApplicationsIcon from '@mui/icons-material/SettingsApplications';
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import DeveloperModeIcon from '@mui/icons-material/DeveloperMode';
import EmojiTransportationIcon from '@mui/icons-material/EmojiTransportation';
import IsoIcon from '@mui/icons-material/Iso';
import { Link as RouterLink } from "react-router-dom";

const drawerWidth = 240;
const mainOptions = [
    {label: 'Vehicles', icon: LocalShippingIcon, href: '/dashboard/vehicles'},
    {label: 'Portfolio Values', icon: AttachMoneyIcon, href: '/dashboard/portfolio'},
    {label: 'Portfolio Forecast', icon: IsoIcon, href: '/dashboard/forecast'},
    {label: 'Breakevens', icon: InsightsIcon, href: '/dashboard/breakevens', disabled: true},
    {label: 'Import', icon: PostAddIcon, href: '/dashboard/import'},
    {label: 'Integrations', icon: IntegrationInstructionsIcon, href: '/dashboard/integrations'},
];
const secondaryOptions = [
    {label: 'Fleet', icon: EmojiTransportationIcon, href: '/dashboard/fleets', disabled: true},
    {label: 'Settings', icon: SettingsApplicationsIcon, href: '/dashboard/settings', disabled: true},
    {label: 'Profile', icon: AccountBoxIcon, href: '/dashboard/profile', disabled: true},
    {label: 'Developer', icon: DeveloperModeIcon, href: '/dashboard/#developer', disabled: true},
]


export default function DashboardNavigationDrawer(props) {

    return (
        <>
            <Drawer
                variant="permanent"
                sx={{
                    width: drawerWidth,
                    flexShrink: 0,
                    [`& .MuiDrawer-paper`]: { top: '7rem', width: drawerWidth, boxSizing: 'border-box' },
                }}
            >
                <Box component={Paper} sx={{ overflow: 'auto' }}>
                    <List>
                        {mainOptions.map((o) => (
                            <ListItem key={o.label} disablePadding>
                                <ListItemButton component={RouterLink} to={o.href} disabled={o.disabled}>
                                    <ListItemIcon>
                                        {<o.icon color={"info"} sx={{ fontSize: 30}}/>}
                                    </ListItemIcon>
                                    <ListItemText primary={o.label} sx={{color:"rgb(80, 200, 255)"}}/>
                                </ListItemButton>
                            </ListItem>
                        ))}
                    </List>
                    <Divider />
                    <List>
                        {secondaryOptions.map((o) => (
                            <ListItem key={o.label} disablePadding>
                                <ListItemButton component={RouterLink} to={o.href} disabled={o.disabled}>
                                    <ListItemIcon> 
                                        {<o.icon sx={{ fontSize: 30, color:"rgb(80, 200, 255)" }}/>}
                                    </ListItemIcon>
                                    <ListItemText primary={o.label} sx={{color: "rgb(80, 200, 255)"}}/>
                                </ListItemButton>
                            </ListItem>
                        ))}
                    </List>
                </Box>
            </Drawer> 
        </>
    );
}