import React, {useRef, useState } from "react";
import Grid from '@mui/material/Grid';
import CssBaseline from '@mui/material/CssBaseline';
import Paper from '@mui/material/Paper';
import Typography from "@mui/material/Typography";
import { Stack } from "@mui/material";
import Button from "@mui/material/Button";
import { Link } from '@mui/material';
import { Link as RouterLink } from "react-router-dom";
import Box from '@mui/material/Box';
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemIcon from '@mui/material/ListItemIcon';
import TripOrigin from "@mui/icons-material/TripOrigin";
import WaitingListSignup from "./WaitingListSignUp";
import Card from '@mui/material/Card';
import Container from '@mui/material/Container';
import CardContent from '@mui/material/CardContent';
import Copyright from "../Copyright";

export default function Landing(props){
    const {cards, FeatureCard1, FeatureCard2} = props;
    const [priceRecordCount] = useState(0);

    const signupRef = useRef(null);
    const executeScroll = () => signupRef.current.scrollIntoView({behavior: "smooth"})
    return(
        <Box component={Paper}>
            <Grid container component={Paper} sx={{ height: '100%' }}>
                <CssBaseline />
                <Grid item md={7}>
                    <Typography
                        component="h2"
                        variant="h2"
                        fontWeight={600}
                        align="center"
                        color="text.primary"
                        gutterBottom
                        mt={8}
                    >
                        Valuation Tools For Fleets
                    </Typography> 
                    <Typography
                        componet="h2"
                        variant="h5"
                        align="center"
                        color="text.primary"
                        mx={8}
                    >
                        FleetHelix provides a platform that surfaces fleet vehicle spot prices, valuation histories
                        and actionable insights for buying and selling your vehicle assets.
                    </Typography>
                    <Stack
                        sx={{ pt: 4 }}
                        direction="row"
                        spacing={2}
                        justifyContent="center"
                        >
                        <Link component={RouterLink} to="/#"><Button onClick={executeScroll}size="large" variant="contained">Try It For Free</Button></Link>
                    </Stack>
                    <Container sx={{ py: 2 }} maxWidth="md">
                        {/* End hero unit */}
                        <Grid container spacing={1} display={'flex'} direction={'column'}>
                            {cards.map((card) => (
                                <Grid item key={card.key} xs={12} sm={12} md={12}>
                                    <Card variant="outlined" sx={{background: "rgb(30,20,35)"}} component={Paper}>
                                        <CardContent sx={{display: 'flex', flexDirection: "row"}}>
                                            <div style={{display: 'flex'}}>
                                                <div style={{marginTop: '0rem', marginBottom: '0rem' }}>
                                                    <card.icon color={card.iconColor} sx={{ fontSize: 80 }}/>
                                                </div>
                                            </div>
                                            <Box ml={5}> 
                                                <Typography gutterBottom variant="h6" component="h2">
                                                    {card.title}
                                                </Typography>
                                                <List>
                                                    {card.bullets.map((b, i) => {
                                                        return(
                                                            <ListItem key={i} disablePadding>
                                                                <ListItemIcon sx={{minWidth: "1.5rem"}}><TripOrigin color="info" sx={{fontSize: 18}}/></ListItemIcon>
                                                                <ListItemText sx={{padding: "0px", color:"rgb(80, 200, 255)"}}>{b}</ListItemText>
                                                            </ListItem>)
                                                        })
                                                    }
                                                </List>
                                                <Typography>
                                                    {card.text}
                                                </Typography>
                                            </Box>
                                        </CardContent>
                                    </Card>
                                </Grid>
                            ))}
                        </Grid>
                    </Container>
                </Grid>
                <Grid className="image-blurred-edge" item xs={false} sm={4} md={5} 
                    sx={{
                        backgroundImage: 'url(./truckinmotion.png)',
                        backgroundRepeat: 'no-repeat',
                        backgroundColor: (t) =>
                            t.palette.mode === 'light' ? t.palette.grey[50] : t.palette.grey[900],
                        backgroundSize: 'cover',
                        backgroundPosition: 'center',
                    }}
                />
            </Grid>
            <Grid container>
                <Grid item xs={12} sm={12} md={7}>
                    <Grid container display={"flex"} my={1} >
                        <FeatureCard1 />
                    </Grid>
                    <Grid container display={"flex"} my={1} mx={0} >
                        <FeatureCard2 />
                    </Grid>
                </Grid>
                <Grid  ref={signupRef} item xs={12} sm={12} md={5} component={Paper} elevation={6} square>
                    <Box className = "gradient-border-bg"
                        sx={{
                            my: 2,
                            mx: 2,
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                        }}
                    >
                        <WaitingListSignup />
                    </Box>
                    <Box
                        sx={{
                            my: 12,
                            mx: 2,
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'left',
                        }}
                    >
                        <Typography sx={{display: "none"}}>
                            Current Dataset Depth: {priceRecordCount}
                        </Typography>
                    </Box>

                </Grid>
                <Grid item>
                    
                </Grid>
            </Grid>
            <Copyright />
        </Box>
    )
}