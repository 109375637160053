import React, { useContext } from "react";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Typography from "@mui/material/Typography";
import { UserContext } from "../../contexts/UserContext";

export default function ImportVehicleInfoView(props){

    let vin = props.vin;
    const user = useContext(UserContext);
    const {
        setVin, 
        year, 
        setYear, 
        make, 
        setMake, 
        model, 
        setModel,
        trim, 
        setTrim, 
        series, 
        setSeries, 
        gvwr, 
        setGVWR, 
        bodyClass, 
        setBodyClass,
        fuelTypePrimary,
        setFuelTypePrimary, 
        handleReset, 
        handleVehicleSubmit,
        inputByVin
    } = props;
    if(!inputByVin && !vin && (year, make, model))vin = `Vehicle ID ${user.fleetVehicleCount+1}`.toUpperCase();

    return(  
        <Box
            component="form"
            display={'flex'}
            onSubmit={handleVehicleSubmit}
            flexDirection={'column'} 
            margin={3} 
            height={'60vh'}
        >
            <Grid container spacing={1}>
                <Grid item md={8} xs={12}>
                    <Button
                        type="submit"
                        fullWidth
                        disabled={vin && year && make && model ? false : true}
                        variant={vin && year && make && model ? "contained" : "outlined"}
                        color={vin && year && make && model ? "success" : "info"}
                        sx={{ mt: 0, mb: 2 }}
                    >
                        Add Vehicle
                    </Button>
                </Grid>
                <Grid item md={4} xs={12}>
                    <Button
                        type="reset"
                        fullWidth
                        variant="outlined"
                        color="info"
                        onClick={handleReset}
                        sx={{mt: 0, mb: 2 }}
                    >
                        Reset
                    </Button>
                </Grid>
                <Grid item xs={12}>
                    {inputByVin && <Typography fontSize={"9pt"}>Fields will Populate on VIN Submittal</Typography>}
                    {!inputByVin && <Typography fontSize={"9pt"}>Fields will Populate on Year, Make, Model Selection</Typography>}
                </Grid>
                <Grid item xs={12}>
                    <TextField sx={{marginTop:"8px"}}
                        margin="normal"
                        required
                        disabled = {vin ? false : true}
                        value={vin ? vin : ""}
                        onChange={(e)=>setVin(e.target.value.toUpperCase())}
                        id="vin"
                        fullWidth
                        label="VIN"
                        placeholder={null}
                        name="vin"
                    />
                </Grid>
                <Grid item xs={12} md={8} lg={3}>
                    <TextField
                        margin="normal"
                        required
                        type="number"
                        disabled = {year ? false : true}
                        value={year ? year : ""}
                        onChange={(e)=>setYear(e.target.value)}
                        id="year"
                        label="Year"
                        name="year"
                    />
                </Grid>
                <Grid item md={9} xs={12}>
                    <TextField
                        margin="normal"
                        required
                        disabled = {make ? false : true}
                        value = {make ? make : ""}
                        onChange={(e)=>setMake(e.target.value)}
                        fullWidth
                        id="make"
                        label="Make"
                        name="make"
                    />  
                </Grid>
                <Grid item xs={6}>
                    <TextField
                        margin="normal"
                        required
                        type="string"
                        disabled = {model ? false : true}
                        value = {model ? model : ""}
                        onChange={(e)=>setModel(e.target.value)}
                        fullWidth
                        id="model"
                        label="Model"
                        name="model"
                    />
                </Grid>
                {((!trim && !series) || (trim)) && 
                    <Grid item xs={6}>
                        <TextField
                            margin="normal"
                            disabled = {(year && make && model && vin) ? false : true}
                            value = {trim ? trim : ""}
                            onChange={(e)=>setTrim(e.target.value)}
                            fullWidth
                            id="trim"
                            label="Trim"
                            name="trim"
                        />
                    </Grid>
                }
                {((!trim && series)) &&
                    <Grid item xs={6}>
                        <TextField
                            margin="normal"
                            disabled = {(year && make && model && vin) ? false : true}
                            value = {series ? series : ""}
                            onChange={(e)=>setSeries(e.target.value)}
                            fullWidth
                            id="series"
                            label="Series"
                            name="series"
                        />
                    </Grid>
                }
                <Grid item xs={12}>
                    <TextField
                        margin="normal"
                        disabled = {(year && make && model && vin) ? false : true}
                        value={gvwr ? gvwr : ""}
                        onChange={(e)=>setGVWR(e.target.value)}
                        fullWidth
                        id="gvwr"
                        label="GVWR"
                        name="gvwr"
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        margin="normal"
                        disabled = {(year && make && model && vin) ? false : true}
                        value={bodyClass ? bodyClass : ""}
                        onChange={(e)=>setBodyClass(e.target.value)}
                        fullWidth
                        id="bodyClass"
                        label="Body Class"
                        name="bodyClass"
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        margin="normal"
                        disabled = {(year && make && model && vin) ? false : true}
                        value={fuelTypePrimary ? fuelTypePrimary : ""}
                        onChange={(e)=>setFuelTypePrimary(e.target.value)}
                        fullWidth
                        id="fuelTypePrimary"
                        label="Primary Fuel"
                        name="fuelTypePrimary"
                    />
                </Grid>
            </Grid>
        </Box>
    );
}