import React from "react";
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Button from '@mui/material/Button';
import Paper from "@mui/material/Paper";
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import { FormControl, InputLabel, Select, MenuItem } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";

export default function DashboardHeaderBar(props){
    const {fleets, selectedFleetId, setSelectedFleetId} = props;

    const handleChange = (value) => {
        setSelectedFleetId(value);
    }
    /*
                <Link color="text.primary" sx={{textDecoration: "none"}} variant="h6" component={RouterLink} to="/dashboard/vehicles/">
                    <Typography variant="h6" color="inherit" noWrap sx={{ flexGrow: 1 }}>
                        FleetHelix     
                    </Typography>
                </Link>
    */

    return(
        <AppBar
            position="static"
            component={Paper}
            color="default"
            elevation={0}
            sx={{ backgroundColor: 'rgb(30,36,36)', borderBottom: (theme) => `1px solid ${theme.palette.divider}` }}
        >
            <Toolbar sx={{ flexWrap: 'wrap' }}>
                <Link color="text.primary" sx={{textDecoration: "none", flexGrow: 1}} variant="h6" component={RouterLink} to="/dashboard/vehicles/">
                    <Typography variant="h5" color="inherit" noWrap sx={{ flexGrow: 1 }}>
                        FleetHelix  
                    </Typography>
                </Link>

                {(fleets && fleets.length > 1) &&
                    <FormControl size={"small"} sx={{width:"15rem"}}>
                        <InputLabel key="fleetPicker" id="fleetPicker">Fleet</InputLabel>
                        <Select
                            labelId="fleet"
                            id="fleet"
                            value={selectedFleetId}
                            label="Fleet"
                            onChange={(e)=>{handleChange(e.target.value)}}
                        >
                            {fleets.map((f, i) => {
                                return (<MenuItem key={i} value={f.fleet_id}>{f.name}</MenuItem>)
                            })}
                        </Select>
                    </FormControl>
                }
                <Button component={RouterLink} to="/signin" variant="outlined" sx={{ my: 1, mx: 1.5 }}>
                    Logout
                </Button>
            </Toolbar>
        </AppBar>
    )
}