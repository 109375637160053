import React, { useState } from "react";
import { createTheme, ThemeProvider } from '@mui/material/styles';
import GlobalStyles from '@mui/material/GlobalStyles';
import CssBaseline from '@mui/material/CssBaseline';
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import { lime, purple } from '@mui/material/colors';
import { Routes, Route } from "react-router-dom";
import Landing from "./components/landing-page-components/Landing";
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import QueryStatsIcon from '@mui/icons-material/QueryStats';
import IsoIcon from '@mui/icons-material/Iso';
import IntegrationInstructionsIcon from '@mui/icons-material/IntegrationInstructions';
import SignInSide from './components/landing-page-components/SignInSide';
import Signup from './components/landing-page-components/Signup';
import Pricing from './components/landing-page-components/Pricing';
import Features from './components/landing-page-components/Features';
import Contact from './components/landing-page-components/Contact';
import WaitingListView from "./components/landing-page-components/WaitingListView";
import DashboardLanding from './components/dashboard-components/DashboardLanding';
import { UserContext } from "./contexts/UserContext";


//default: 'rgb(60,65,65)'
const defaultTheme = createTheme({
    palette: {
        mode: 'dark',
        primary: lime,
        secondary: purple,
        background: {
            default: 'rgb(30,35,35)'
        },
    },
});


export default function FleetHelix(props){
    const [token, setToken] = useState(null);
    const [primaryFleetId, setPrimaryFleetId] = useState(null);
    const [fleetVehicleCount, setFleetVehicleCount] = useState(0);

    const settings = require('./settings.json');

    const userObj = {
        name: "",
        token: token,
        primaryFleetId: primaryFleetId,
        fleetVehicleCount: fleetVehicleCount,
        setToken: setToken,
        setPrimaryFleetId: setPrimaryFleetId,
        setFleetVehicleCount: setFleetVehicleCount,
        apiUrl: (settings.environment === "preproduction" || settings.environment === "production") ? "https://api.fleethelix.com/" : "https://api.fleethelix.com/"
    };

    const cards = [
        {
            key: 1,
            icon: IsoIcon,
            iconColor: 'secondary',
            title: 'Price Prediction',
            text: '',
            bullets: [
                `Get up to twelve months of resale pricing seasonality and prediction.`,
                `Maximize the residual value of your fleets assets by timing the market.`,
                `Minimize the risk you buy or sell operating assets at the wrong time.`
            ]
        },
        {
            key: 0,
            icon: AttachMoneyIcon,
            iconColor: 'primary',
            title: 'Spot Prices',
            bullets: [
                `Get real time and up to date prices for your vehicles. Instantly.`,
                `Discover and document your fleets current residual value.`,
                `Stay up to date on market trends for the vehicles you deploy.`
            ]
        },
        {
            key: 2,
            icon: QueryStatsIcon,
            iconColor: 'primary',
            title: 'Replacement Breakevens',
            text: '',
            bullets: [
                `Find when it is most cost effective to retire and replace a vehicle.`,
                `Get estimates for remaining economic lifespan of your vehicles.`,
                `Plan for next CapEx and procurement cycle with a data driven approach.`
            ]
        },
        {
            key: 3,
            icon: IntegrationInstructionsIcon,
            iconColor: 'secondary',
            title: 'Integrations',
            text: ``,
             bullets: [
                `Consolidate Telematics and Fleet Management Data Streams. `,
                `Manage Your Data and Use it to Inform Fleet Financial Decisions.`,
                `Easily import hundreds of vehicles for our financial analysis toolset.`
             ]
        }
    ];
    const FeatureCard1 = (props) => {
        return(
            <> 
                <Grid className="image-blurred-edge"
                    height={"350px"}
                    item xs={12} md={6}
                    sx={{
                        backgroundImage: 'url(./price-history-2.png)',
                        backgroundRepeat: 'no-repeat',
                        backgroundSize: 550,
                        backgroundPosition: 10, 
                    }}
                />
                <Grid className="image-blurred-edge" 
                    sx={{backgroundImage: 'none'}}
                    item xs={12} md={6}>
                    <Box mt={6}>
                        <Typography variant="h6" align="center" my={3}>Insights</Typography>
                        <Typography mx={3}>
                            Real time and historic pricing of your vehicles gives you an edge
                            when making decisions on asset disposition and acquisition decisions.
                            You can view pricing by asset, by asset class or on the totality 
                            of your fleet. Pricing is sourced continously from public and proprietary 
                            data streams and AI powered price models.
                        </Typography>
                    </Box>
                </Grid>
            </>
            );
    }

    const FeatureCard2 = (props) => {
        return(
            <>
                <Grid className="image-blurred-edge" 
                    sx={{backgroundImage: 'none'}}
                    item xs={12} md={6}
                >
                    <Box mr={0} pr={0} mt={6}>
                        <Typography variant="h6" align="center" my={3}>Monitoring</Typography>
                        <Typography mx={3}>
                            What is the present value of my fleet on an
                            asset by asset basis? Answer how that is changing
                            now as opposed to 6 months ago, and how it has 
                            changed on year over year basis.
                            Document and report on asset values with easy to
                            download and use reports in Excel and PDF
                            formats.
                        </Typography>
                    </Box>
                </Grid>
                <Grid className="image-blurred-edge"
                    height={"350px"}
                    item xs={false} sm={false} md={6}
                    sx={{
                        backgroundImage: 'url(./asset-prices-1.png)',
                        backgroundRepeat: 'no-repeat',
                        backgroundSize: 550,
                        backgroundPosition: 10,  
                }}/>
            </>
        );
    }

    return(
        <ThemeProvider theme={defaultTheme}>
            <UserContext.Provider value={userObj}>
                <GlobalStyles styles={{ ul: { margin: 0, padding: 0, listStyle: 'none' } }} />
                <CssBaseline />
                <Box component={Paper}>
                    <Routes>
                        {(settings.environment === "production" || settings.environment === "development") && 
                            <>
                                <Route path="/" element={<Landing 
                                    theme={defaultTheme} 
                                    cards={cards}
                                    FeatureCard1={FeatureCard1}
                                    FeatureCard2={FeatureCard2}
                                />} />
                                <Route path="signin/" element={<SignInSide />} />
                                <Route path="signup/" element={<Signup />} />
                                <Route path="pricing/" element={<Pricing />} />
                                <Route path="features/" element={<Features />} />
                                <Route path="contact/" element={<Contact />} />
                                <Route path="dashboard/*" element= {<DashboardLanding />} />
                            </>
                        }
                        {settings.environment === "preproduction" && <Route path="/*"
                            element={<WaitingListView 
                                theme={defaultTheme} 
                                cards={cards}
                                FeatureCard1={FeatureCard1}
                                FeatureCard2={FeatureCard2}
                            />} 
                        />}
                    </Routes>
                </Box>
            </UserContext.Provider>
        </ThemeProvider>
    )
}