import React, { useState, useEffect, useContext } from "react";
import Paper from "@mui/material/Paper";
import Box from '@mui/material/Box';
import { useNavigate, Routes, Route } from "react-router-dom";
import DashboardHeaderBar from "./DashboardHeaderBar";
import DashboardNavigationDrawer from "./DashboardNavigationDrawer";
import VehiclesView from "./VehiclesView";
import VehicleStatsView from "./VehicleStatsView";
import PortfolioView from "./PortfolioView";
import ForecastView from "./ForecastView";
import ImportView from "./ImportView";
import FleetView from "./FleetView";
import BreakevenView from "./BreakevenView";
import IntgerationView from "./IntegrationView";
import Contact from "../landing-page-components/Contact";
import { UserContext } from "../../contexts/UserContext";
import BetaAlert from "./BetaAlert";


export default function DashboardLanding(props){
    const [fleets, setFleets] = useState([]);
    const [selectedFleetId, setSelectedFleetId] = useState(null);
    const user = useContext(UserContext);
    const GET_FLEETS_URL = `${user.apiUrl}getFleets/`;
    const navigate = useNavigate();

    useEffect(() => {
        if(!user.token)return;
        fetch(GET_FLEETS_URL, {headers:{Authorization: `Bearer ${user.token}`}})
            .then((resp) => resp.json())
            .then((data) => {
                const arr = [];
                data.data.forEach((f)=>{arr.push(f)});
                setFleets(arr);
                if(arr.length > 0)setSelectedFleetId(arr[0].id);
                console.log(arr[0].id);
                user.setFleetVehicleCount(parseInt(arr[0].vehicle_count));
            })
            .catch((err) => {
                console.error(err);
            })
            // eslint-disable-next-line
    }, []);

    useEffect(() => {
        if(user && !user.token)navigate("/signin");
        // eslint-disable-next-line
    }, []);

    if(!user.token){
        return(<></>)
    }

    return(
        <>     
            <BetaAlert message={"Beta Preview: Some FleetHelix features are limited or disabled now but will be fully avaliable in the coming days."}/>
            <DashboardHeaderBar 
                fleets={fleets} 
                selectedFleetId={selectedFleetId} 
                setSelectedFleetId={setSelectedFleetId}
            />
            <Box component={Paper} display={'flex'}>
                <DashboardNavigationDrawer />
                <Routes>
                    <Route path="/" element={<VehiclesView selectedFleetId={selectedFleetId}/>} />
                    <Route path="/vehicles" element={<VehiclesView selectedFleetId={selectedFleetId} />} />
                    <Route path="/vehicle-stats" element={<VehicleStatsView selectedFleetId={selectedFleetId}/>} />
                    <Route path="/portfolio" element={<PortfolioView selectedFleetId={selectedFleetId}/>} />
                    <Route path="/forecast" element={<ForecastView selectedFleetId={selectedFleetId}/>} />
                    <Route path="/breakevens" element={<BreakevenView selectedFleetId={selectedFleetId}/>} />
                    <Route path="/import" element={<ImportView selectedFleetId={selectedFleetId}/>} />
                    <Route path="/integrations" element={<IntgerationView />} />
                    <Route path="/fleets" element={<FleetView selectedFleetId={selectedFleetId} fleets={fleets} setFleets={setFleets}/>} />
                    <Route path="/support" element={<Contact />}/>
                </Routes>
            </Box>
        </>
    )
}