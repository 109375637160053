import React, { useState, useContext } from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Link from '@mui/material/Link';
import { Link as RouterLink, useNavigate } from "react-router-dom";
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import DashboardHeaderBar from './SiteHeaderBar';
import FailureAlert from '../dashboard-components/FailureAlert';
import SuccessAlert from '../dashboard-components/SuccessAlert';
import { UserContext } from '../../contexts/UserContext';

export default function SignUp() {
  const user = useContext(UserContext);
  const navigate = useNavigate();
  const CREATE_USER_URL = `${user.apiUrl}createUser`;

  const [openFailureAlert, setOpenFailureAlert] = useState(false);
  const [openSuccessAlert, setOpenSuccessAlert] = useState(false);
  
  const handleSubmit = (event) => {
    event.preventDefault();
    const tierId = 0;
    const data = new FormData(event.currentTarget);
    const body = JSON.stringify({email: data.get('email'), password: data.get('password'), tier_id: tierId})
    fetch(CREATE_USER_URL, {method: 'POST', body: body, headers:{"Content-Type": "application/json"}})
      .then((resp) => resp.json())
      .then((data) =>{ 
        if(data.status === 409){
          setOpenFailureAlert(true);
          return;
        }
        else if(data.data && data.data.authenticated && data.data.token){
          user.setToken(data.data.token);
          user.setPrimaryFleetId(data.data.primary_fleet_id);
          setOpenSuccessAlert(true);
          return;
        }
      });
  };

  return (
    <>
      <DashboardHeaderBar />
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Sign up
          </Typography>
          <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 3 }}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  id="email"
                  label="Email Address"
                  name="email"
                  autoComplete="email"
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  name="password"
                  label="Password"
                  type="password"
                  id="password"
                  autoComplete="new-password"
                />
              </Grid>
            </Grid>
            <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
            >
                Sign Up
            </Button>
            <Grid container justifyContent="flex-end">
              <Grid item>
                <Link component={RouterLink} to="/signin" variant="body2">
                  Already have an account? Sign in
                </Link>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Container>
      <FailureAlert message={"A user account using this email already exists."} open={openFailureAlert} setOpen={setOpenFailureAlert}/>
      <SuccessAlert message={"Your user account has been created successfully."} open={openSuccessAlert} setOpen={setOpenSuccessAlert} reset={()=>{navigate("/dashboard")}}/>
    </>
  );
}