

const VEHICLE_COUNT_LIMIT = 10;

export default function priceForecast(opts, vehicleArray, responseHandler){

    const apiUrl = opts.apiUrl;
    const user= opts.user;
    const GET_VEHICLE_PRICE_HISTORY_URL = `${apiUrl}getVehiclesPriceHistory`;

    const vehicles = vehicleArray.slice(0, VEHICLE_COUNT_LIMIT);
    const result = [];
    const finish = (cb, result) => {
        if(cb && result)cb(result);
    }
    const promises = [];
    vehicles.forEach((v) => {
        promises.push(fetch(`${GET_VEHICLE_PRICE_HISTORY_URL}?vehicles=[${v.ymmIds}]&periodMonths=24`, {headers:{Authorization: `Bearer ${user.token}`}}).then((resp)=> resp.json()));
    });
    promises.forEach((p, idx) => {
        p.then(async (data) => {
            const trend = _calculateTrend(data.data);
            const seasonality = _calculateSeasonalityCoF(data.data, trend);
            const lastPrice = await _lastPrice(data.data);
            const twelveMonthForecast = _twelveMonthForecast(lastPrice, seasonality, trend);
            result.push({id: vehicles[idx].id, twelveMonthForecast: twelveMonthForecast, priceHistory: data.data});
        })
        p.then(() => {
            //if(result.length === promises.length)
        });
    });
    //allow async calcs to finish with a hacky timeout...
    setTimeout(() => {finish(responseHandler, result);
}, 500);
}

const _lastPrice = async (array) => {
    let result = 0;
    array.forEach((a)=>{
        if(parseFloat(a.price) > 0)result = a.price;
    })
    return parseFloat(result);
}

const _calculateTrend = (array) => {
    let lastPrice = 0;
    let changePct = 0;
    let trendCount = 0;

    array.forEach((d, i) => {
        if(i > 0){
            lastPrice = array[i-1].price;
            if(lastPrice > 0 && d.price > 0){
                const cp = 100*(d.price-lastPrice)/lastPrice;
                if(!isNaN(cp) && isFinite(cp)){
                    changePct += cp;
                    trendCount++;
                }
            }
        }
    });
    return changePct/trendCount;
}

const _calculateSeasonalityCoF = (array, trend) => {
    const arr = Array(12);
    const result = Array(12).fill(0);
    let lastPrice = 0;
    array.forEach((d, i) => {  
        if(i > 0){
            lastPrice = array[i-1].price;
            if(lastPrice > 0 && d.price > 0){
                const cp = 100*(d.price-lastPrice)/lastPrice;
                if(!isNaN(cp) && isFinite(cp)){
                    if(typeof arr[d.month-1] === "undefined")arr[d.month-1] = {delta: 0, count: 0};
                    arr[d.month-1].delta += cp;
                    arr[d.month-1].count++;
                }
            }
        }
    });
    arr.forEach((a, i) => {
        result[i] = a.delta/a.count;
        result[i] = (result[i] - trend);
    });
    return result;
}

const _twelveMonthForecast = (startingPrice, seasonality, trend) => {
    const result = Array(12);
    const now = {year: new Date().getFullYear(), month: new Date().getMonth()}
    let month = now.month;

    //set first forecast month by trend
    result[0] = startingPrice + (startingPrice * (trend/100));

    //set first forecast month
    result[0] = result[0] + (startingPrice * (seasonality[month]/100));
  
    for(let i=0;i<=11;i++){
        //get next month
        month = month < 11 ? month += 1 : 0;
        if(i === 0)continue;
        //apply trend
        result[i] = result[i-1] + (result[i-1] * (trend/100));
        //apply seasonality
        result[i] = result[i] + (result[i-1] * (seasonality[month]/100));
        //trim false precision
        result[i] = Math.round(result[i] / 50)*50
    }
    //trim false precision from start point(put here to keep forecast precision internally)
    result[0] = Math.round(result[0] / 50)*50

    return result;
}