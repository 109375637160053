import React, { useState, useEffect } from "react";
import { Typography } from "@mui/material";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import { LineChart } from "@mui/x-charts";
import { shortMonth } from "../../utilities/utilities";
//import { UserContext } from "../../contexts/UserContext";

const Tableau10 = [
    '#4e79a7',
    '#f28e2c',
    '#e15759',
    '#76b7b2',
    '#59a14f',
    '#edc949',
    '#af7aa1',
    '#ff9da7',
    '#9c755f',
    '#bab0ab',
  ];

export default function SingleVehicleForecastGraphViewModal(props){
    const {selectedVehicle, priceForecasts, open, setOpen} = props;

    const [priceSeries, setPriceSeries] = useState([0,0]);
    //const [showPriceHistory] = useState(false);
    const [xAxis, setXAxis] = useState([0,0]);
    const [minPrice, setMinPrice] = useState(0);
    const [maxPrice, setMaxPrice] = useState(0);
    //const user = useContext(UserContext);

    const INCLUDED_HISTORY_MONTHS = 2;
    const INCLUDED_FORECAST_MONTHS = 6;
    const PRICE_HEADROOM = 1000;


    useEffect(() => {
        //get xAxis vals
        if(!priceForecasts || !selectedVehicle)return;
        const xAxis = [];
        const pf = priceForecasts.find((p) => p.id === selectedVehicle.id);
        const ph = pf.priceHistory.slice(pf.priceHistory.length-INCLUDED_HISTORY_MONTHS);
        let month = 0;
        let year = 0;
        ph.forEach((p) => {
            month = parseInt(p.month);
            year = parseInt(p.year);
            xAxis.push(`${shortMonth(month)} ${year-2000}`);
        });
        pf.twelveMonthForecast.slice(0, INCLUDED_FORECAST_MONTHS).forEach((p) => {
            month++;
            if(month > 12){
                month = 1;
                year++;
            }
            xAxis.push(`${shortMonth(month)} ${year-2000}`);
        });
        setXAxis(xAxis);
        
    }, [priceForecasts, selectedVehicle]);
    
    useEffect(() => {
        //set priceforecast
        if(!priceForecasts || !selectedVehicle)return;
        const combo = Array(INCLUDED_FORECAST_MONTHS+INCLUDED_HISTORY_MONTHS).fill(null);
        const forecast = Array(INCLUDED_FORECAST_MONTHS+INCLUDED_HISTORY_MONTHS).fill(null);
        const history = Array(INCLUDED_FORECAST_MONTHS+INCLUDED_HISTORY_MONTHS).fill(null);
        const pf = priceForecasts.find((p) => p.id === selectedVehicle.id);
        let min = null;
        let max = null;

        pf.priceHistory.slice(pf.priceHistory.length-INCLUDED_HISTORY_MONTHS).map((p) => parseInt(p.price)).forEach((m,i) => {
            m = Math.round(m * selectedVehicle.discountCoF);
            m = Math.round(m / 50)*50
            if(i===0){
                min = m;
                max = m;
            }
            history[i] = m;
            combo[i] = m;
            if(m){
                min = Math.min(m, min);
                max = Math.max(m, max);
            }
        });
        forecast[INCLUDED_HISTORY_MONTHS-1] = history[INCLUDED_HISTORY_MONTHS-1];
        pf.twelveMonthForecast.slice(0, INCLUDED_FORECAST_MONTHS).forEach((m, i) => {
            m = Math.round(m * selectedVehicle.discountCoF);
            m = Math.round(m / 50)*50
            forecast[i+INCLUDED_HISTORY_MONTHS] = m;
            combo[i+INCLUDED_HISTORY_MONTHS] = m;
            if(m){
                min = Math.min(m, min);
                max = Math.max(m, max);
            }
        });
        
        //combo = [...history, ...pf.twelveMonthForecast.slice(0, INCLUDED_FORECAST_MONTHS)];
        /*
            {
                data: forecast,
                color: "rgba(242, 142, 44, 1)",
                label: `${selectedVehicle.year} ${selectedVehicle.model} - Forecast`
            },
            {
                data: history,
                color: "rgba(255, 126, 0, 1)",
                label: `${selectedVehicle.year} ${selectedVehicle.model} - History`
            },
        */


        const series = [
            {
                data: combo,
                color: selectedVehicle ? Tableau10[4] : Tableau10[Math.floor(Math.random()*Tableau10.length)],
                label: `${selectedVehicle.year} ${selectedVehicle.model}`
            }
        ];
        setMinPrice(min-PRICE_HEADROOM);
        setMaxPrice(max+PRICE_HEADROOM);
        setPriceSeries(series);

    },[priceForecasts, selectedVehicle])

    const style = {
        position: 'absolute',
        top: '25%',
        left: '40%',
        transform: 'translate(-25%, -25%)',
        width: '700px',
        height: '50 vh',
        bgcolor: 'rgb(70, 70, 70)',
        border: '2px solid #FFF',
        boxShadow: 24,
        p: 4,
    };
    if(!selectedVehicle)return(<></>);
    if(!priceSeries || !priceSeries[0].data || priceSeries[0].data.length < 1)return<></>
    return(
        <>
            <Modal
                open={open}
                onClose={()=>{setOpen(false)}}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <Typography id="modal-modal-title" variant="h6" component="h2">
                        {`${INCLUDED_FORECAST_MONTHS} Month Resale Price Forecast`}
                    </Typography>
                    {xAxis && priceSeries && <LineChart 
                        xAxis={[{ data: xAxis, scaleType: 'band', }]}
                        yAxis={[{min: minPrice, max: maxPrice}]}
                        series={priceSeries}
                        
                        width={700}
                        height={350}
                        sx={{'& .MuiLineElement-root': {
                            strokeDasharray: '15 5',
                            strokeWidth: 6,
                          },
                        }}
                        slotProps={{
                            legend: {
                              direction: 'row',
                              position: { vertical: 'top', horizontal: 'right' },
                              padding: {right: 100}
                            },
                        }}
                    />}
                    {selectedVehicle &&
                        <>
                            <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                                {selectedVehicle.year} {selectedVehicle.make} {selectedVehicle.model} 
                            </Typography>
                            <Typography id="modal-modal-description" sx={{ mt: 0 }}>
                                {selectedVehicle.condition} Condition | Average Miles
                            </Typography>
                        </>
                    }
                </Box>
            </Modal>
        </>
    );
}