import React, { useState, useEffect, useContext } from "react";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Paper from '@mui/material/Paper';
import Button from "@mui/material/Button";
import { DataGrid, useGridApiRef } from '@mui/x-data-grid';
import Typography from "@mui/material/Typography";
import { UserContext } from "../../contexts/UserContext";
import priceForecast from "../../analytics/priceForecast";
import SingleVehicleForecastGraphViewModal from "./SingleVehicleForecastGraphViewModal";

export default function ForecastView(props){
    const {selectedFleetId} = props;
    const apiRef = useGridApiRef();
    const [vehicles, setVehicles] = useState([]);
    const [conditions, setConditions] = useState(null);
    const [graphModalOpen, setGraphModalOpen] = useState(false);
    const [selectedVehicleIds, setSelectedVehicleIds] = useState([]);
    const [selectedVehicles, setSelectedVehicles] = useState(null);
    const [priceForecasts, setPriceForecasts] = useState(null);

    const user = useContext(UserContext);
    const VEHICLES_GET_URL = `${user.apiUrl}getVehicles/`;
    const GET_VEHICLE_CONDITIONS = `${user.apiUrl}getVehicleConditionsList/`;
    
    useEffect(() => {
        fetch(GET_VEHICLE_CONDITIONS, {headers:{Authorization: `Bearer ${user.token}`}})
            .then((resp) => resp.json())
            .then((data) => {
                setConditions(data.data);
            })
            .catch((err) => console.error(err));
        // eslint-disable-next-line
    },[]);

    useEffect(() => {
        const arr = vehicles.filter((v) => selectedVehicleIds.indexOf(v.id) > -1);
        setSelectedVehicles(arr);
        // eslint-disable-next-line
    }, [selectedVehicleIds]);

    useEffect(()=>{
        //get vehicles on load
        if(!selectedFleetId || !conditions)return;
        fetch(`${VEHICLES_GET_URL}?fleetId=${selectedFleetId}`, {headers:{Authorization: `Bearer ${user.token}`}})
            .then((resp) => resp.json())
            .then((data) => {
                const arr = [];
                if(data.data)data.data.forEach((v)=>{
                    arr.push(formatVehicle(v));
                });
                console.log(arr);
                priceForecast({apiUrl: user.apiUrl, user: user}, arr, (res)=>{setPriceForecasts(res)});
                setVehicles(arr);
            })
            .catch((err)=>console.error(err));
            // eslint-disable-next-line
    },[selectedFleetId, conditions]);

    const formatVehicle = (vehicle) => {
        const v = vehicle;
        const condition = conditions.find((c) => c.name === v.condition);
        v.discountCoF = condition ? 1-(condition.discount/100) : 1;
        v.id = v.pkid;
        v['asset-vin'] = v.asset ? v.asset : v.vin;
        v['trim-series'] = v.trim;
        if(v.trim && v.series)v['trim-series'] = `${v.trim}/${v.series}`;
        else if(!v.trim && v.series)v['trim-series'] = v.series;
        v.spotPrice = `${Math.round(parseFloat(v.currentPrice))}`;
        v.spotPrice = (v.discountCoF * v.spotPrice).toFixed(0);
        v.price30 = v.discountCoF * parseFloat(v.price30);
        v.price180 = v.discountCoF * parseFloat(v.price180);
        v.price365 = v.discountCoF * parseFloat(v.price365);
        
        //vehicle change vals
        const change30 = (100*((v.spotPrice - v.price30)/v.price30)).toFixed(1);
        const change180 = (100*((v.spotPrice - v.price180)/v.price180)).toFixed(1);
        const change365 = (100*((v.spotPrice - v.price365)/v.price365)).toFixed(1);

        v.trend30 = `${isNaN(change30) ? 'N/A' : `${change30}%`}`;
        v.trend180 = `${isNaN(change180) ? 'N/A' : `${change180}%`}`;
        v.trend365 = `${isNaN(change365) ? 'N/A' : `${change365}%`}`;
        v.coverageRating = v.priceConfidence/10;
        return v;
    }

    useEffect(() => {
        if(!vehicles || !priceForecasts || priceForecasts.length < 1)return;
        const arr = [...vehicles];
        arr.forEach((v) => {
            const pf = priceForecasts.find((f) => f.id === v.id);
            if(!pf)return;
            //vehicle change vals
            const change30 = (100*(((v.discountCoF * pf.twelveMonthForecast[0]) - v.spotPrice)/v.spotPrice)).toFixed(1);
            const change90 = (100*(((v.discountCoF * pf.twelveMonthForecast[2]) - v.spotPrice)/v.spotPrice)).toFixed(1);
            const change180 = (100*(((v.discountCoF * pf.twelveMonthForecast[5]) - v.spotPrice)/v.spotPrice)).toFixed(1);
            const change365 = (100*(((v.discountCoF * pf.twelveMonthForecast[11]) - v.spotPrice)/v.spotPrice)).toFixed(1);

            v.forecast30 = `${isNaN(change30) ? 'N/A' : `${change30}%`}`;
            v.forecast90 = `${isNaN(change30) ? 'N/A' : `${change90}%`}`;
            v.forecast180 = `${isNaN(change30) ? 'N/A' : `${change180}%`}`;
            v.forecast365 = `${isNaN(change30) ? 'N/A' : `${change365}%`}`;
            //free tier hobble
            //v.forecast180 = "N/A";
            v.forecast365 = "N/A";
        });
        //this relies on some js spookiness and modifies the array objs without updating the arr...
        //MUI Datagrid is doing some under the hood updates on the vehicles objects that make this work
        //and break things if you try to update state properly here
        //setVehicles(arr);
    }, [priceForecasts, vehicles]);

    const spotPriceCellFormatter = (cell) => {
        let c = "white";
        return (
            <span>
                <span style={{color: c}}>${cell}</span>
            </span>
        );
    }

    const trendCellFormatter = (cell) => {
        if(typeof cell === "undefined")return(<span></span>)
        cell = String(cell);
        let c = "green";
        let s = '\u0394';
        if(cell && cell.includes && cell.includes('-')){
            c = parseFloat(cell) > -10 ? "white" : "red";
            s = '\u2207';
        }
        if(cell && cell.includes('N/A')){
            c="white";
            s="";
        }

        return (
            <span>
                <span style={{color: c, fontWeight: 800}}>{cell}{s}</span>
            </span>
        );
    };

    const columns = [
        { key: -1, field: 'space', headerName: '', width: 1, editable: false},
        { key: 0, field: 'asset-vin', headerName: 'Asset/VIN', width: 150, editable: false },
        { key: 1, field: 'spotPrice', headerName: 'Spot Price', width: 120, editable: false, renderCell: (params) => {return spotPriceCellFormatter(params.value);}, },
        { key: 2, 
            field: 'forecast30', 
            headerName: '30 Forecast \u00A0', 
            width: 120, 
            editable: false,     
            renderCell: (params) => {return trendCellFormatter(params.value);},
        },
        { key: 11, field: 'forecast90', headerName: '3 Month \u00A0', width: 120, editable: false, renderCell: (params) => {return trendCellFormatter(params.value);},},
        { key: 3, field: 'forecast180', headerName: '6 Month \u00A0', width: 120, editable: false, renderCell: (params) => {return trendCellFormatter(params.value);},},
        { key: 4, field: 'forecast365', headerName: 'Yearly \u00A0', width: 120, editable: false, renderCell: (params) => {return trendCellFormatter(params.value);}, },
        { key: 5, field: 'coverageRating', headerName: 'Coverage Rating', width: 120, editable: false },
        { key: 6, field: 'condition', headerName: 'Condition', width: 100, editable: false },
        { key: 7, field: 'year', headerName: 'Year', width: 100, editable: false },
        { key: 8, field: 'make', headerName: 'Make', width: 150, editable: false }, 
        { key: 9, field: 'model', headerName: 'Model', width: 150, editable: false }, 
        { key: 10, field: 'trim-series', headerName: 'Trim/Series', width: 150, editable: false },       
    ];
    const now = new Date();
    return(
        <>
            <Grid container component={Paper}>
                <Grid item xs={12} md={6} ml={1}>
                    <Typography sx={{textDecoration: 'none'}} color="white" variant="h6">{now.getMonth()+1}/{now.getDate()}/{now.getFullYear()} Vehicle Resale Market Forecast </Typography>
                </Grid>
                <Grid item xs={12} md={6} ml={1}>
                    <Typography sx={{textDecoration: 'none'}} color={(theme)=>theme.palette.grey[500]} variant="body">Paid plans include 180 and YoY price forecasts</Typography>
                </Grid>
                <Grid item xs={12} display={"flex"} justifyContent={"flex-end"} mr={5} mb={1}>
                    <Button sx={{width: "16rem"}}
                        variant={selectedVehicleIds.length === 1 ? "contained" : "outlined"}
                        color="info"
                        onClick={()=>{if(selectedVehicles.length > 0)setGraphModalOpen(true)}}
                    >
                        Open Forecast
                    </Button>
                </Grid>

                <Grid item xs={12}>
                    <Box style={{ height: '85vh', width: '80vw'}}>
                        {(vehicles && columns.length > 0) && 
                            <DataGrid 
                                apiRef={apiRef}
                                initialState={{
                                    sorting: {
                                    sortModel: [{ field: 'asset-vin', sort: 'asc' }],
                                    },
                                }}
                                columns={columns}
                                rows={[...vehicles]}
                                checkboxSelection
                                onRowSelectionModelChange={(m)=>setSelectedVehicleIds(m)}
                                localeText={{ noRowsLabel: "You have no vehicles. Use the import or integrations sections to add some." }} 
                            />
                        }
                    </Box>
                </Grid>
            </Grid>
            {selectedVehicles?.length === 1 && priceForecasts.length > 0 &&
                <SingleVehicleForecastGraphViewModal priceForecasts={priceForecasts} selectedVehicle={selectedVehicles[0]} open={graphModalOpen} setOpen={setGraphModalOpen}/>
            }
        </>
    )
}