import React, {useState, useEffect, useContext} from "react";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { DataGrid, useGridApiRef } from '@mui/x-data-grid';
import { UserContext } from '../../contexts/UserContext';



export default function BreakevenView(props){
    const {selectedFleetId} = props;

    const [conditions, setConditions] = useState([]);
    const [allowEdits, setAllowEdits] = useState(false);
    const [vehicles, setVehicles] = useState([]);
    const [vehicleClasses, setVehicleClasses] = useState([]);

    const user = useContext(UserContext);
    const VEHICLES_GET_URL = `${user.apiUrl}getVehicles/`;
    const GET_VEHICLE_CONDITIONS = `${user.apiUrl}getVehicleConditionsList/`;
    const VEHICLE_UPDATE_URL = `${user.apiUrl}updateVehicle/`;
    const GET_VEHICLE_CLASSES = `${user.apiUrl}getVehicleClasses/`;
    const apiRef = useGridApiRef();

    useEffect(() => {
        if(!user.token)return;
        fetch(GET_VEHICLE_CLASSES, {headers:{Authorization: `Bearer ${user.token}`}})
            .then((resp) => resp.json())
            .then((data) => {
                const arr = []
                if(data.data){
                    data.data.forEach((d)=>arr.push(d.class));
                    setVehicleClasses(arr);
                }
            })
            .catch((err)=>console.error(err));
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        if(!user.token)return;
        fetch(GET_VEHICLE_CONDITIONS, {headers:{Authorization: `Bearer ${user.token}`}})
            .then((resp) => resp.json())
            .then((data) => {
                const arr = []
                if(data.data){
                    data.data.forEach((d)=>arr.push(d.name));
                    setConditions(arr);
                }
            })
            .catch((err)=>console.error(err));
            // eslint-disable-next-line
    }, []);

    const updateVehicleRecord = (vehicle, originalVehicle) => {
        //editable vals: asset, year, make, model, trim, series, gvwr, bodyClass, fuelTypePrimary
        const obj = {
                pkid: vehicle.pkid,
                asset: vehicle.asset, 
                year: parseInt(vehicle.year, 10),
                make: vehicle.make, 
                model: vehicle.model,
                condition: vehicle.condition,
                trim: vehicle.trim, 
                series: vehicle.series, 
                gvwr: vehicle.gvwr, 
                bodyClass: vehicle.bodyClass, 
                fuelTypePrimary: vehicle.fuelTypePrimary
            };
        if(typeof obj.year !== 'number' || obj.year < 1901 || obj.year > 2050){
            //TODO: reinstate last viable year value
            //TODO: put in an alert modal
            window.alert(`Bad year value: ${obj.year} ${typeof obj.year}`);
            return;
        } 
        const json = JSON.stringify(obj);
        fetch(VEHICLE_UPDATE_URL, 
            {method: 'POST', body: json, headers:{"Content-Type": "application/json", Authorization: `Bearer ${user.token}`}})
            .then((resp)=> resp.json()) 
            .then((data)=> {
                //TODO handle api error and send msg
                const arr = [];
                vehicles.forEach((v)=>{if(v.pkid !== vehicle.pkid)arr.push(v)});
                arr.push(vehicle);
                setVehicles(arr);
            })
            .catch((err)=>console.error(err));
    }

    const columns = [
        { key: 0, field: 'asset', headerName: 'Asset', width: 150, editable: allowEdits },
        { key: 1, field: 'vin', headerName: 'VIN', width: 200, editable: false },
        { key: 2, 
            field: 'year', 
            headerName: 'Year', 
            width: 100, 
            editable: allowEdits, 
            type: "string"
            },
        { key: 3, field: 'make', headerName: 'Make', width: 150, editable: allowEdits }, 
        { key: 4, field: 'model', headerName: 'Model', width: 150, editable: allowEdits }, 
        { key: 5, 
            field: 'yearly_maintenance', 
            headerName: 'Maintenance', 
            width: 150, 
            type: "number",
            editable: allowEdits },  
        { key: 10, 
            field: 'condition', 
            headerName: 'Condition', 
            width: 100, 
            editable: allowEdits, 
            type: "singleSelect",
            valueOptions: conditions },
        { key: 11, 
            field: 'vehicleClass', 
            headerName: 'Class', 
            width: 100, 
            editable: allowEdits, 
            type: "singleSelect",
            valueOptions: vehicleClasses },
    ];


    useEffect(()=>{
        //get vehicles on load
        if(!selectedFleetId || !conditions)return;
        fetch(`${VEHICLES_GET_URL}?fleetId=${selectedFleetId}`, {headers:{Authorization: `Bearer ${user.token}`}})
            .then((resp) => resp.json())
            .then((data) => {
                const arr = [];
                if(data.data)data.data.forEach((v)=>{
                    arr.push(formatVehicle(v));
                });
                setVehicles(arr);
            })
            .catch((err)=>console.error(err));
            // eslint-disable-next-line
    },[selectedFleetId, conditions]);

    const formatVehicle = (vehicle) => {
        const v = vehicle;
        const condition = conditions.find((c) => c.name === v.condition);
        v.discountCoF = condition ? 1-(condition.discount/100) : 1;
        v.id = v.pkid;
        v['asset-vin'] = v.asset ? v.asset : v.vin;
        v['trim-series'] = v.trim;
        if(v.trim && v.series)v['trim-series'] = `${v.trim}/${v.series}`;
        else if(!v.trim && v.series)v['trim-series'] = v.series;
        v.spotPrice = `${Math.round(parseFloat(v.currentPrice))}`;
        v.spotPrice = (v.discountCoF * v.spotPrice).toFixed(0);
        v.price30 = v.discountCoF * parseFloat(v.price30);
        v.price180 = v.discountCoF * parseFloat(v.price180);
        v.price365 = v.discountCoF * parseFloat(v.price365);
        
        //vehicle change vals
        const change30 = (100*((v.spotPrice - v.price30)/v.price30)).toFixed(1);
        const change180 = (100*((v.spotPrice - v.price180)/v.price180)).toFixed(1);
        const change365 = (100*((v.spotPrice - v.price365)/v.price365)).toFixed(1);

        v.trend30 = `${isNaN(change30) ? 'N/A' : `${change30}%`}`;
        v.trend180 = `${isNaN(change180) ? 'N/A' : `${change180}%`}`;
        v.trend365 = `${isNaN(change365) ? 'N/A' : `${change365}%`}`;
        v.coverageRating = v.priceConfidence/10;
        return v;
    }

    //const vehicleStats = useMemo(() => {
//
//    })

    return(
        <>
            <Grid container>
                <Box>
                    
                </Box>
                <Grid item xs={12} ml={4} mt={2}>
                    <Typography sx={{textDecoration: 'none'}} color="white" variant="h6">
                        Breakeven View - Edit or update vehicle attributes.
                    </Typography>
                    
                </Grid>
                <Grid item xs={12} md={3} lg={2} padding={1}>
                    <Button 
                        variant="outlined" 
                        color={allowEdits ? "info" : "info"}
                        onClick={()=>{setAllowEdits(allowEdits ? false : true)}}
                        fullWidth>
                        {allowEdits ? "Disable Editing" : "Enable Editing"}
                    </Button>
                </Grid>
                <Grid item xs={12}>
                    <Box style={{ height: '85vh', width: '80vw'}}>
                        {(vehicles && columns.length > 0 && conditions && vehicleClasses) && 
                        <DataGrid 
                            apiRef={apiRef}
                            initialState={{
                                sorting: {
                                  sortModel: [{ field: 'asset', sort: 'asc' }],
                                },
                              }}
                            disableMultipleRowSelection={true}
                            //checkboxSelection
                            processRowUpdate={(updatedRow, originalRow) =>
                               updateVehicleRecord(updatedRow, originalRow)
                            }
                            onProcessRowUpdateError={() => {}}
                            localeText={{ noRowsLabel: "You have no vehicles. Use the import or integrations sections to add some." }} 
                            rows={vehicles}
                            columns={columns}
                            slotProps={{
                                baseCheckbox: {color: "info"}
                            }}
                        />}
                    </Box>
                </Grid>
            </Grid>
            
        </>
    )
}