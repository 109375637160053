import React, { useState, useEffect, useContext } from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { FormControl, InputLabel, Select, MenuItem } from "@mui/material";
import { UserContext } from '../../contexts/UserContext';

const YEARS_TO_SUPPORT = 25;

export default function SingleYmmInput(props){

    const {year, make, model, setYear, setMake, setModel, isTeaser} = props;
    const displayVINPrompt = isTeaser ? "none" : true;
    const [models, setModels] = useState([]);
    const [makes, setMakes] = useState([]);
    const [years, setYears] = useState([]);

    const user = useContext(UserContext);
    const MAKES_URL = `${user.apiUrl}getMakes/`;
    const MODELS_URL = `${user.apiUrl}getModels/`;

    useEffect(() => {
        const arr = [];
        let year = new Date().getFullYear()+1;
        while(new Date().getFullYear() - year <= YEARS_TO_SUPPORT){
            arr.push(year);
            year--;
        }

        setYears(arr);
    },[]);

    useEffect(() => {
        if(!year)return;
        fetch(`${MAKES_URL}?year=${year}`, {headers:{Authorization: `Bearer ${user.token}`}})
            .then((resp) => resp.json())
            .then((data) => {
                const arr = [];
                data.data.forEach((m) => {
                    arr.push(m.make);
                });
                setMakes(arr);
            })
            // eslint-disable-next-line
    },[year]);

    useEffect(() => {
        if(!make || !year)return;
        fetch(`${MODELS_URL}?make=${make}&year=${year}`, {headers:{Authorization: `Bearer ${user.token}`}})
            .then((resp) => resp.json())
            .then((data) => {
                const arr = [];
                data.data.forEach((m) => {
                    arr.push(m.model);
                });
                setModels(arr);
            });
            // eslint-disable-next-line
    },[make, year]);

    const titleText = isTeaser ? "" : "Import Vehicles by Year, Make, Model"
    if(years.length < 1 || makes.length < 1 || models.length < 1)return(<></>)
    return(
        <Box>
            <Grid container>
                <Grid item xs={12} ml={2}>
                    <Typography mb={2} ml={2} variant="h6">
                        {titleText}
                    </Typography>
                    <Typography mb={2} ml={2} variant="p" paragraph display={displayVINPrompt}>
                        If available, importing vehicles by Vehicle Identification Number(VIN) will be more accurate.
                    </Typography>
                    <Grid container spacing={1}>
                        <Grid item mb={2} sm={12} md={4}>
                            <FormControl fullWidth>
                                <InputLabel key="year" id="year">Year</InputLabel>
                                <Select
                                    labelId="year"
                                    id="year"
                                    value={year}
                                    label="Year"
                                    onChange={(e)=>{setYear(e.target.value)}}
                                >
                                    {years && years.length > 0 && years.map((y, i) => {
                                        return (<MenuItem key={i} value={y}>{y}</MenuItem>)
                                    })}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item sm={12} md={8}>
                            <FormControl fullWidth>
                                <InputLabel key="make" id="make">Make</InputLabel>
                                <Select
                                    labelId="make"
                                    id="make"
                                    disabled={(!makes || makes.length < 1) ? true : false}
                                    value={make}
                                    label="Make"
                                    onChange={(e)=>{setMake(e.target.value)}}
                                >
                                    {years && makes.length > 0 && makes.map((m, i) => {
                                        return (<MenuItem key={i} value={m}>{m}</MenuItem>)
                                    })}
                                </Select>
                            </FormControl>
                        </Grid>
                    </Grid> {/*end container year, make*/}
                    <Grid container>
                        <Grid item xs={12}>
                            <FormControl fullWidth>
                                <InputLabel key="year" id="make">Model</InputLabel>
                                <Select
                                    labelId="model"
                                    id="model"
                                    disabled={(!models || models.length < 1) ? true : false}
                                    value={model}
                                    label="Make"
                                    onChange={(e)=>{setModel(e.target.value)}}
                                >
                                    {years && makes.length > 0 && models.map((m, i) => {
                                        return (<MenuItem key={i} value={m}>{m}</MenuItem>)
                                    })}
                                </Select>
                            </FormControl>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Box>
    )
}