
module.exports = {
    shortMonth: shortMonth,
}


function shortMonth(month){
    switch(true) {
        case month === 1:
            return "Jan";
        case month === 2:
            return "Feb";
        case month === 3:
            return "Mar";
        case month === 4:
            return "Apr";
        case month === 5:
            return "May";
        case month === 6:
            return "Jun";
        case month === 7:
            return "Jul";
        case month === 8:
            return "Aug";
        case month === 9:
            return "Sep";
        case month === 10:
            return "Oct";
        case month === 11:
            return "Nov";
        case month === 12:
            return "Dec";
        default:
            console.error('your number dont make sense: ', month, typeof month);
            return "bullshit"
      }
}