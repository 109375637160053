import React, { useState, useContext } from "react";
import Copyright from '../Copyright';
import { TextField } from "@mui/material";
import DashboardHeaderBar from "./SiteHeaderBar";
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import { UserContext } from "../../contexts/UserContext";
import { useNavigate } from "react-router-dom";
import SuccessAlert from "../dashboard-components/SuccessAlert";

export default function Contact(props){
    const SUPPORT_EMAIL_URL = 'sendSupportEmail';
    const [successAlertOpen, setSuccessAlertOpen] = useState(false);

    const user = useContext(UserContext);
    const navigate = useNavigate();
    
    const handleSubmit = (event) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);
        const body = {
          email: data.get('email'),
          subject: data.get('subject'),
          message: data.get('message'),
        };
        fetch(`${user.apiUrl}${SUPPORT_EMAIL_URL}`, 
            {method: 'POST', body: JSON.stringify(body), 
            headers:{"Content-Type": "application/json"}})
            .then((resp) => resp.json())
            .then((data) => {
                if(data.message === 'email sent')setSuccessAlertOpen(true);
            })
    }

    return(
        < >

            <DashboardHeaderBar />
            {/* Hero unit */}
            <Container 
                disableGutters 
                maxWidth="sm" 
                sx={{ pt: 8, pb: 6 }}>
                <Typography
                    component="h1"
                    variant="h2"
                    align="center"
                    color="text.primary"
                    gutterBottom
                >
                    Contact
                </Typography>
                <Typography variant="h5" align="center" color="text.secondary" component="p">
                    Give us feedback on FleetHelix, ask questions or request features.
                </Typography>
            </Container>
            {/* End hero unit */}
            <Container 
                maxWidth="sm" 
                component="form" 
                onSubmit={handleSubmit}
                sx={{ pt: 1, pb: 6 }}>
                <TextField
                    required
                    fullWidth
                    id="subject"
                    label="Subject"
                    name="subject"
                    sx={{marginTop: "1rem"}}
                />
                <TextField
                    required
                    fullWidth
                    id="email"
                    label="Email Address"
                    name="email"
                    autoComplete="email"
                    sx={{marginTop: "1rem"}}
                />
                <TextField 
                    required
                    fullWidth
                    multiline
                    id="message"
                    label="Message"
                    name="message"
                    rows={5}
                    sx={{marginTop: "1rem"}}
                />
                <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    sx={{ mt: 3, mb: 2 }}
                >
                    Submit
                </Button>
            </Container>
            <Copyright />
            <SuccessAlert 
                open={successAlertOpen} 
                setOpen={setSuccessAlertOpen} 
                reset={()=>{navigate("/dashboard")}}
                message={"Your message has been sent."}/> 
        </>
    );
}