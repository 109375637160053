import React, { useState, useContext, useEffect } from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Link from '@mui/material/Link';
import { useNavigate, Link as RouterLink } from "react-router-dom";
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Copyright from '../Copyright';
import DashboardHeaderBar from './SiteHeaderBar';
import FailureAlert from '../dashboard-components/FailureAlert';
import { UserContext } from '../../contexts/UserContext';


export default function SignInSide() {
  const user = useContext(UserContext);
  //const SIGNIN_URL = `${user.apiUrl}getUser`;
  const SIGNIN_URL = `${user.apiUrl}login`;
  const SESSION_AUTH_URL = `${user.apiUrl}authenticateSession`;
  const navigate = useNavigate();

  const [failureAlertOpen, setFailureAlertOpen] = useState(false);

  useEffect(() => {
    fetch(SESSION_AUTH_URL)
      .then((resp) => resp.json())
      .then((data) => {
        //console.log(data);
      })
      // eslint-disable-next-line
  },[]);

  const handleSubmit = (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    const body = {
      username: data.get('email'),
      password: data.get('password'),
    };
    fetch(SIGNIN_URL, {method: 'POST', body: JSON.stringify(body), headers:{"Content-Type": "application/json"}})
      .then((resp) => resp.json())
      .then((data) => {
        if(data.data && data.data.authenticated && data.data.token){
          user.setToken(data.data.token);
          navigate("/dashboard");
        }
      })
      .catch((err) => {
        console.error(err);
        setFailureAlertOpen(true);
      })
  };

  return (
    <>
      <DashboardHeaderBar/>
      <Grid container component="main" sx={{ height: '95vh' }}>
        <CssBaseline />
        <Grid
          item
          xs={false}
          sm={4}
          md={7}
          sx={{
            backgroundImage: 'url(./truckinmotion.png)',
            backgroundRepeat: 'no-repeat',
            backgroundColor: (t) =>
              t.palette.mode === 'light' ? t.palette.grey[50] : t.palette.grey[900],
            backgroundSize: 'cover',
            backgroundPosition: 'center',
          }}
        />
        <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
          <Box
            sx={{
              my: 8,
              mx: 4,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
              <LockOutlinedIcon />
            </Avatar>
            <Typography component="h1" variant="h5">
              Sign in
            </Typography>
            <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 1 }}>
              <TextField
                margin="normal"
                required
                fullWidth
                id="email"
                label="Email Address"
                name="email"
                autoComplete="email"
                autoFocus
              />
              <TextField
                margin="normal"
                required
                fullWidth
                name="password"
                label="Password"
                type="password"
                id="password"
                autoComplete="current-password"
              />
              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
              >
                Sign In
              </Button>
              <Grid container>
                <Grid item xs>
                  <Link component={RouterLink} to="/contact" variant="body2">
                    Forgot password?
                  </Link>
                </Grid>
                <Grid item>
                  <Link component={RouterLink} to="/signup" variant="body2">
                    {"Don't have an account? Sign Up"}
                  </Link>
                </Grid>
              </Grid>
              <Copyright sx={{ mt: 5 }} />
            </Box>
          </Box>
        </Grid>
      </Grid>
      <FailureAlert 
        open={failureAlertOpen} 
        setOpen={setFailureAlertOpen} 
        message={"Failed to Authenticate. Check your email address and password."}
      />
    </>
  );
}