import React, { useState, useEffect, useContext } from "react";
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Box from "@mui/material/Box";
import Paper from '@mui/material/Paper';
import Typography from "@mui/material/Typography";
import { DataGrid, useGridApiRef } from '@mui/x-data-grid';
import { Link as RouterLink } from "react-router-dom";
import { UserContext } from "../../contexts/UserContext";



export default function VehiclesView(props){
    const {selectedFleetId} = props;

    const [vehicles, setVehicles] = useState(null);
    const [allowEdits, setAllowEdits] = useState(true);
    const [allowDelete, setAllowDelete] = useState(false);
    const [conditionsList, setConditionsList] = useState([]);

    const apiRef = useGridApiRef();
    const user = useContext(UserContext);

    const VEHICLES_GET_URL = `${user.apiUrl}getVehicles/`;
    const VEHICLE_UPDATE_URL = `${user.apiUrl}updateVehicle/`;
    const VEHICLE_DELETE_URL = `${user.apiUrl}deleteVehicle/`;
    const VEHICLE_CONDITIONS_LIST_URL = `${user.apiUrl}getVehicleConditionsList/`;

    const columns = [
            { key: 0, field: 'asset', headerName: 'Asset', width: 150, editable: allowEdits },
            { key: 1, field: 'vin', headerName: 'VIN', width: 200, editable: false },
            { key: 10, 
                field: 'condition', 
                headerName: 'Condition', 
                width: 100, 
                editable: allowEdits, 
                type: "singleSelect",
                valueOptions: conditionsList },
            { key: 2, 
                field: 'year', 
                headerName: 'Year', 
                width: 100, 
                editable: allowEdits, 
                type: "string"
                },
            { key: 3, field: 'make', headerName: 'Make', width: 150, editable: allowEdits }, 
            { key: 4, field: 'model', headerName: 'Model', width: 150, editable: allowEdits }, 
            { key: 5, field: 'trim', headerName: 'Trim', width: 150, editable: allowEdits }, 
            { key: 6, field: 'series', headerName: 'Series', width: 150, editable: allowEdits }, 
            { key: 7, field: 'gvwr', headerName: 'GVWR', width: 150, editable: allowEdits }, 
            { key: 8, field: 'bodyClass', headerName: 'Body Class', width: 150, editable: allowEdits },
            { key: 9, field: 'fuelTypePrimary', headerName: 'Fuel Type(1)', width: 150, editable: allowEdits },        
    ];

    useEffect(()=>{
        if(!selectedFleetId || !user.token)return;
        //get vehicles on load
        fetch(`${VEHICLES_GET_URL}?fleetId=${selectedFleetId}`, {headers:{Authorization: `Bearer ${user.token}`}})
            .then((resp) => resp.json())
            .then((data) => {
                if(data.data)data.data.forEach((v)=>v.id = v.pkid);
                setVehicles(data.data);
            })
            .catch((err)=>console.error(err));
            // eslint-disable-next-line
    },[selectedFleetId]);

    useEffect(() => {
        if(!user.token)return;
        fetch(VEHICLE_CONDITIONS_LIST_URL, {headers:{Authorization: `Bearer ${user.token}`}})
            .then((resp) => resp.json())
            .then((data) => {
                const arr = []
                if(data.data){
                    data.data.forEach((d)=>arr.push(d.name));
                    setConditionsList(arr);
                }
            })
            .catch((err)=>console.error(err));
            // eslint-disable-next-line
    }, []);

    const deleteVehicleRecord = (vehicle) => {     
        if(!vehicle || !vehicle.vin || !vehicle.fleetId || !user.token){
            return;
        }   
        const obj = {vin: vehicle.vin, fleet_id: vehicle.fleetId}
        const json = JSON.stringify(obj);
        fetch(VEHICLE_DELETE_URL, 
            {method: 'POST', body: json, headers:{"Content-Type": "application/json", Authorization: `Bearer ${user.token}`}})
            .then((resp)=> resp.json()) 
            .then((data)=> {
                //TODO handle api error and send msg
                const arr = [];
                vehicles.forEach((v)=>{if(v.pkid !== vehicle.pkid)arr.push(v)});
                setVehicles(arr);
            })
            .catch((err)=>console.error(err));
    }

    const updateVehicleRecord = (vehicle, originalVehicle) => {
        //editable vals: asset, year, make, model, trim, series, gvwr, bodyClass, fuelTypePrimary
        const obj = {
                pkid: vehicle.pkid,
                asset: vehicle.asset, 
                year: parseInt(vehicle.year, 10),
                make: vehicle.make, 
                model: vehicle.model,
                condition: vehicle.condition,
                trim: vehicle.trim, 
                series: vehicle.series, 
                gvwr: vehicle.gvwr, 
                bodyClass: vehicle.bodyClass, 
                fuelTypePrimary: vehicle.fuelTypePrimary
            };
        if(typeof obj.year !== 'number' || obj.year < 1901 || obj.year > 2050){
            //TODO: reinstate last viable year value
            //TODO: put in an alert modal
            window.alert(`Bad year value: ${obj.year} ${typeof obj.year}`);
            return;
        } 
        const json = JSON.stringify(obj);
        fetch(VEHICLE_UPDATE_URL, 
            {method: 'POST', body: json, headers:{"Content-Type": "application/json", Authorization: `Bearer ${user.token}`}})
            .then((resp)=> resp.json()) 
            .then((data)=> {
                //TODO handle api error and send msg
                const arr = [];
                vehicles.forEach((v)=>{if(v.pkid !== vehicle.pkid)arr.push(v)});
                arr.push(vehicle);
                setVehicles(arr);
            })
            .catch((err)=>console.error(err));
    }

    return(
        <>

            <Grid container component={Paper}>
                <Grid item xs={12} ml={4} mt={2}>
                    <Typography sx={{textDecoration: 'none'}} color="white" variant="h6">
                        Edit or update vehicle attributes.
                    </Typography>
                    
                </Grid>
                <Grid container spacing={2} >
                    <Grid item xs={12} sm={4} md={4} lg={2}>
                        <Button 
                            variant="outlined" 
                            fullWidth
                            color={allowEdits ? "info" : "info"}
                            onClick={()=>{setAllowEdits(allowEdits ? false : true)}}
                        >
                            {allowEdits ? "Disable Editing" : "Enable Editing"}
                        </Button>
                    </Grid>
                    {/*TODO disable and add an upgrade option??*/}
                    <Grid item xs={12} sm={4} md={2} lg={2}>
                        <Button 
                            variant="outlined"
                            fullWidth
                            disabled={false}
                            color={"success"}
                            component={RouterLink}
                            to="/dashboard/vehicle-stats"
                        >
                            Stats
                        </Button>
                    </Grid> 
                    <Grid item xs={12} sm={4} md={2} lg={2}>
                        <Button 
                            variant={allowDelete ? "contained" : "outlined"} 
                            disabled={allowDelete ? false : true}
                            fullWidth
                            color="warning"
                            onClick={()=>{
                                //deletes the first selected record/not every selected record
                                const a = Array.from(apiRef.current.getSelectedRows().values());
                                deleteVehicleRecord(a[0])
                            }}
                            >
                            Delete
                        </Button>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <Box style={{ height: '85vh', width: '80vw'}}>
                        {(vehicles && columns.length > 0) && 
                        <DataGrid 
                            apiRef={apiRef}
                            initialState={{
                                sorting: {
                                  sortModel: [{ field: 'asset', sort: 'asc' }],
                                },
                              }}
                            disableMultipleRowSelection={true}
                            //checkboxSelection
                            processRowUpdate={(updatedRow, originalRow) =>
                               updateVehicleRecord(updatedRow, originalRow)
                            }
                            onRowSelectionModelChange={(arr) => {arr.length > 0 ? setAllowDelete(true) : setAllowDelete(false)}}
                            onProcessRowUpdateError={() => {}}
                            localeText={{ noRowsLabel: "You have no vehicles. Use the import or integrations sections to add some." }} 
                            rows={vehicles}
                            columns={columns}
                            slotProps={{
                                baseCheckbox: {color: "info"}
                            }}
                        />}
                    </Box>
                </Grid>
            </Grid>
        </>
    )
}